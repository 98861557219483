import React, { CSSProperties, Ref, useEffect, useRef, useState } from "react";
import {
  BellOutlined,
  DownOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { Badge, Button } from "antd";
import { useTranslation } from "react-i18next";
import { Dropdown } from "antd";
import { BrowserView } from "react-device-detect";
import { UserMenu } from "../components/UserMenu";
import { SideMenu } from "../components/SideMenu";
import { useHistory } from "react-router-dom";
import { ROUTE_HOME } from "../Constants";
import {
  selectProfile,
  updateUserPic,
  selectUserPicPath,
  selectNotifications,
  retrieveNotifications,
} from "../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import NotificationList from "../components/NotificationList";
import { FallBackType, SafeImage } from "../components/SafeImage";
import Cookies from "js-cookie";
import { COOKIE_BASE } from "../util/CookieManager";

interface MainLayoutProps {
  hideFooter?: boolean;
  showHelp?: boolean;
  contentStyle?: CSSProperties;
  contentClassName?: string;
  onHelp?: () => void;
}

const Header = React.forwardRef((props, ref: Ref<HTMLDivElement>) => {
  const { t } = useTranslation();
  const history = useHistory();

  const dispatch = useAppDispatch();

  let notifCount = useAppSelector(selectNotifications)?.pendingNotifications;

  const profile = useAppSelector(selectProfile);
  const userName = profile?.name + " " + profile?.surname;

  const userImage = useAppSelector(selectUserPicPath);

  useEffect(() => {
    if (profile?.userPic?.file) {
      dispatch(updateUserPic(profile.userPic.file));
    }
  }, [profile]);

  return (
    <div id="header" className="header" ref={ref}>
      <div style={{ width: 200 }}>
        <img
          className="logo"
          src="/assets/images/logo_fw.png"
          alt="logo"
          style={{ cursor: "pointer" }}
          onClick={() => history.push(ROUTE_HOME)}
        />
      </div>

      <div
        style={{
          alignSelf: "center",
          display: "flex",
          height: "100%",
          alignItems: "center",
        }}
      >
        <Dropdown
          overlay={<NotificationList />}
          trigger={["click"]}
          overlayStyle={{ border: "1px solid lightgray" }}
        >
          <div className="notifications">
            <div style={{ color: "white" }}>{t("Notifiche")}</div>
            <Badge count={notifCount} overflowCount={10}>
              <BellOutlined
                style={{ marginLeft: 10, fontSize: 25, color: "white" }}
                onClick={() =>
                  dispatch(retrieveNotifications(profile?.personId!))
                }
              />
            </Badge>
          </div>
        </Dropdown>

        <div style={{ display: "flex", height: "100%" }}>
          <BrowserView>
            <Dropdown
              overlay={<UserMenu />}
              trigger={["click"]}
              overlayStyle={{ border: "1px solid lightgray" }}
            >
              <div className="menu">
                <div className="border" />
                <div className="name">{userName}</div>
                <div style={{ WebkitTransform: "translate3d(0,0,0)" }}>
                  <SafeImage
                    className="image"
                    src={userImage}
                    alt="user"
                    fallback={FallBackType.INITIALS}
                    fullname={
                      profile
                        ? { name: profile.name, surname: profile.surname }
                        : undefined
                    }
                  />
                </div>

                <div style={{ padding: 20 }}>
                  <DownOutlined style={{ width: 20, color: "white" }} />
                </div>
              </div>
            </Dropdown>
          </BrowserView>
        </div>
      </div>
    </div>
  );
});

const Footer = React.forwardRef((props, ref: Ref<HTMLDivElement>) => {
  return (
    <div id="footer" className="footer" ref={ref}>
      <div style={{ fontSize: 12 }}>Copyright © 2021 Family World Srl</div>
      <Button type="primary" style={{ height: 40, margin: 5 }}>
        Aumenta il tuo spazio
      </Button>
    </div>
  );
});

const MainLayout: React.FunctionComponent<MainLayoutProps> = ({
  hideFooter = true,
  children,
  showHelp,
  contentStyle,
  contentClassName,
  onHelp,
}) => {
  const header = useRef<HTMLDivElement>(null);
  const footer = useRef<HTMLDivElement>(null);

  const [siderHeight, setSiderHeight] = useState("calc(100vh - 150px)");

  useEffect(() => {
    let headerHeight = header.current?.offsetHeight ?? 0;
    let footerHeight = hideFooter ? 0 : footer.current?.offsetHeight ?? 80;
    setSiderHeight("calc(100vh - " + (headerHeight + footerHeight) + "px)");
  }, [hideFooter]);

  return (
    <div className="main-layout">
      <Header ref={header} />

      <div style={{ display: "flex", flex: "auto" }}>
        <div className="side-menu-container" style={{ height: siderHeight }}>
          <SideMenu
            showHelp={showHelp}
            hideFooter={hideFooter}
            onHelp={onHelp}
          />
        </div>

        <div
          className={
            "content" + (contentClassName ? ` ${contentClassName}` : "")
          }
          style={{
            height: siderHeight,
            width: "calc(100vw - 202px)",
            ...contentStyle,
          }}
        >
          {children}
        </div>
      </div>

      {hideFooter ? null : <Footer ref={footer} />}
    </div>
  );
};

export default MainLayout;
