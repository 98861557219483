import React, { useEffect, useState } from "react";
import { Button, Modal, Form, Input, Select, message } from "antd";
import {
  MultiplePostCreationInterface,
  PostCreationInterface,
} from "../service/feedAPI";
import { GenericGroupInterface, GroupInterface } from "../types/GroupTypes";
import { FileDto } from "./files/Types";
import { SafeImage } from "./SafeImage";
import { FileOutlined } from "@ant-design/icons";
import { parseMultiGroup, parseOneToOneGroup } from "../util/GroupUtil";
import { useAppSelector } from "../store/hooks";
import { selectConnections } from "../store/auth/authSlice";
import PeopleList, { ListPersonDto } from "./feed/PeopleList";
import update from "immutability-helper";
import { toast } from "react-toastify";

interface CreatePostFromFilesModalProps {
  files?: FileDto[];
  createMultiplePost: (
    dto: MultiplePostCreationInterface,
    groupIds: string[]
  ) => void;
  onUnshareFile?: (groupId: string, fileId: string) => void;
  close: () => void;
  userId: string;
}

const CreatePostFromFilesModal: React.FunctionComponent<
  CreatePostFromFilesModalProps
> = ({ files, createMultiplePost, onUnshareFile, close, userId }) => {
  const [form] = Form.useForm();

  const connections = useAppSelector(selectConnections);

  const [originalMembers, setOriginalMembers] = useState<ListPersonDto[]>([]);
  const [members, setMembers] = useState<ListPersonDto[]>([]);
  const [filter, setFilter] = useState<string>("");
  const [openUnshareFileModal, setOpenUnshareFileModal] =
    useState<boolean>(false);
  const [personToUnshareFile, setPersonToUnshareFile] =
    useState<ListPersonDto>();
  const [disabledConfirmButton, setDisableConfirmButton] = useState(true);

  const submit = (values: any) => {
    let share: string[] = [];
    members.forEach((m) => {
      if (m.selected == true) share.push(m.personId);
    });

    createMultiplePost(
      {
        text: values.description ?? "",
        attachments: files!.map((file) => ({ fileId: file.fileId })),
      },
      share
    );
  };

  const closeForm = () => {
    form.resetFields();
    setDisableConfirmButton(true);
    close();
  };

  useEffect(() => {
    let _groups: GenericGroupInterface[] = [];
    connections?.groups.forEach((g) => {
      _groups.push(parseMultiGroup(g));
    });
    connections?.friends.forEach((g) => {
      _groups.push(parseOneToOneGroup(g, userId));
    });

    if (files != null && files.length == 1) {
      let file = files[0];

      let memId: string[] = [];
      _groups.forEach((g) => {
        g.members.forEach((p) => {
          if (!memId.some((id) => p.personId === id)) memId.push(p.personId);
        });
      });

      let memIdReduced: string[] = [];
      file.sharedToPerson?.forEach((e) => {
        if (memId.some((p) => p === e.person.personId))
          memIdReduced.push(e.person.personId);
      });

      let _groupsReduced: GenericGroupInterface[] = [];
      _groups.forEach((g) => {
        if (!g.members.some((p) => memIdReduced.includes(p.personId)))
          _groupsReduced.push(g);
      });

      let _toCanAdd: string[] = [];
      _groupsReduced.forEach((g) => {
        if (!_toCanAdd.some((id) => g.groupId === id))
          _toCanAdd.push(g.groupId);
      });

      let mem: ListPersonDto[] = [];
      _groups.forEach((g) => {
        let a: ListPersonDto = {
          personId: g.groupId,
          name: g.name,
          shared:
            g.members.length > 2 ? undefined : !_toCanAdd.includes(g.groupId),
          surname: "",
          gender: "",
          leaf: false,
          horizontal: false,
        };
        mem.push(a);
      });

      mem.sort((a, b) => {
        if (a.selected && !b.selected) return -1;
        if (!a.selected && b.selected) return 1;
        return 0;
      });

      setMembers(mem);
      setOriginalMembers(mem);
    }

    if (files != null && files.length > 1) {
      let memAll: ListPersonDto[] = [];
      _groups.forEach((g) => {
        let a: ListPersonDto = {
          personId: g.groupId,
          name: g.name,
          selected: false,
          surname: "",
          gender: "",
          leaf: false,
          horizontal: false,
        };
        memAll.push(a);
      });

      setMembers(memAll);
      setOriginalMembers(memAll);
    }
  }, [files]);

  useEffect(() => {
    let filteredList = [...originalMembers];
    filteredList = filteredList.filter((m) => {
      return m.name.toLowerCase().includes(filter.toLowerCase());
    });

    setMembers(filteredList);
  }, [filter]);

  useEffect(() => {
    setDisableConfirmButton(members.every((mem) => !mem.selected));
  }, [members]);

  const getMemberToUnshareFile = (item: ListPersonDto, index: number) => {
    setOpenUnshareFileModal(true);
    setPersonToUnshareFile(members[index]);
  };

  const selectMember = (m: ListPersonDto, i: number) => {
    setMembers((list) => update(list, { [i]: { selected: { $set: true } } }));
    setDisableConfirmButton(false);
    originalMembers.filter(
      (mem) => mem.personId == members[i].personId
    )[0].selected = true;
  };

  const unselectMember = (m: ListPersonDto, i: number) => {
    setMembers((list) => update(list, { [i]: { selected: { $set: false } } }));

    originalMembers.filter(
      (mem) => mem.personId == members[i].personId
    )[0].selected = false;
  };

  const unshareFile = () => {
    if (!(personToUnshareFile && personToUnshareFile.personId))
      toast.error("Selezionare un membro o gruppo", {
        position: "bottom-right",
        hideProgressBar: true,
      });

    if (onUnshareFile) {
      onUnshareFile(personToUnshareFile?.personId!, files![0].fileId);

      members.filter(
        (m) => m.personId == personToUnshareFile?.personId
      )[0].shared = false;
      originalMembers.filter(
        (m) => m.personId == personToUnshareFile?.personId
      )[0].shared = false;
    }

    setOpenUnshareFileModal(false);
  };

  return (
    <>
      <Modal
        zIndex={1003}
        visible={!!files}
        footer={[
          <Button onClick={closeForm}>Annulla</Button>,
          <Button
            type="primary"
            onClick={() => form.submit()}
            disabled={disabledConfirmButton}
          >
            Conferma
          </Button>,
        ]}
        centered
        destroyOnClose
        maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
        bodyStyle={{ padding: 40 }}
        onCancel={closeForm}
      >
        <Form layout="vertical" form={form} onFinish={submit}>
          <Form.Item name="filesearchbox" label="Cerca membro">
            <Input allowClear onChange={(e) => setFilter(e.target.value)} />
          </Form.Item>
          <Form.Item name="filemembers" label="Membri">
            <PeopleList
              people={members}
              selectPerson={selectMember}
              unselectPerson={unselectMember}
              unshareFile={getMemberToUnshareFile}
            />
          </Form.Item>
          <div className="img-horizontal-list" style={{ padding: "20px 0" }}>
            {files &&
              files.map((file) => (
                <SafeImage
                  key={file.fileId}
                  src={file.thumbnail_uri}
                  style={{ height: 80 }}
                  fallback={
                    <div className="centered" style={{ width: 80, height: 80 }}>
                      <FileOutlined style={{ fontSize: 30 }} />
                    </div>
                  }
                />
              ))}
          </div>
          <Form.Item name="description" label="Descrizione">
            <Input.TextArea />
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        visible={openUnshareFileModal}
        title={"Annulla Condivisione"}
        footer={[
          <Button onClick={() => setOpenUnshareFileModal(false)}>
            Annulla
          </Button>,
          <Button type="primary" onClick={unshareFile}>
            Elimina
          </Button>,
        ]}
        centered
        destroyOnClose
        maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
        bodyStyle={{ padding: 40 }}
        onCancel={() => setOpenUnshareFileModal(false)}
        width={"fit-content"}
      >
        Vuoi annullare la condivisione con{" "}
        <text style={{ fontWeight: "bold" }}>{personToUnshareFile?.name}</text>?
      </Modal>
    </>
  );
};

export default CreatePostFromFilesModal;
