import { StripePrice, Subscription } from "../types/PaymentTypes";

export const isActive = (sub: Subscription): boolean => {
  return (
    (sub.currentPeriodEnd > new Date().getTime() ||
      sub.trialEnd > new Date().getTime()) &&
    (sub.status === "active" || sub.status === "trialing")
  );
};

export const isOnOtherStore = (sub: Subscription): boolean => {
  return sub.store === "google" || sub.store === "apple";
};

export const isCustomSub = (sub: Subscription): boolean =>
  sub.store === "custom";

const currency_simbols = {
  USD: "$", // US Dollar
  EUR: "€", // Euro
  CRC: "₡", // Costa Rican Colón
  GBP: "£", // British Pound Sterling
  ILS: "₪", // Israeli New Sheqel
  INR: "₹", // Indian Rupee
  JPY: "¥", // Japanese Yen
  KRW: "₩", // South Korean Won
  NGN: "₦", // Nigerian Naira
  PHP: "₱", // Philippine Peso
  PLN: "zł", // Polish Zloty
  PYG: "₲", // Paraguayan Guarani
  THB: "฿", // Thai Baht
  UAH: "₴", // Ukrainian Hryvnia
  VND: "₫", // Vietnamese Dong
};

export const bytesToGB = (bytes?: number) => {
  return Math.round(((bytes ?? 0) / 1024 / 1024 / 1024) * 10) / 10;
};

const getCurrencySymbol = (isoCode: string | undefined) => {
  var a = 0;
  return a
    .toLocaleString("en", {
      style: "currency",
      currency: isoCode?.toUpperCase(),
    })
    .replace("0.00", "");
};

export const getPriceLabel = (price: StripePrice) => {
  return `${getCurrencySymbol(price.currency)} ${(
    price.unitAmount! / 100
  ).toString()}`;
};

export const getRecurringLabel = (price: StripePrice) => {
  let intervalLabel = "";

  if (price.recurring !== null) {
    intervalLabel =
      (price.recurring?.intervalCount ?? 0) > 1
        ? `${price.recurring!.intervalCount} `
        : "";
    switch (price.recurring!.interval) {
      case "day":
        if (price.recurring!.intervalCount > 1) intervalLabel += "giorni";
        intervalLabel += "giorno";
        break;
      case "week":
        if (price.recurring!.intervalCount > 1) intervalLabel += "settimane";
        intervalLabel += "settimana";
        break;
      case "month":
        if (price.recurring!.intervalCount > 1) intervalLabel += "mesi";
        intervalLabel += "mese";
        break;
      case "year":
        if (price.recurring!.intervalCount > 1) intervalLabel += "anni";
        intervalLabel += "anno";
    }
  } else return null;

  return intervalLabel;
};
