import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "..";
import { getTourStatus, sendTourCompleted } from "../../service/tourAPI";
import { Tour } from "../../tour/types";

export interface Status {
  tree: number;
  home: number;
  feed: number;
  files: number;
  subscription: number;
}

export const retrieveTourStatus = createAsyncThunk(
  "tour/retrieveStatus",
  async (userId: string) => {
    const results = await getTourStatus(userId);
    if (results && "tree" in results) return results;
    return initialState.status;
  }
);

export const tourCompleted = createAsyncThunk(
  "tour/sendCompleted",
  async (payload: { userId: string; tour: Tour }, { dispatch }) => {
    const result = await sendTourCompleted(
      payload.userId,
      payload.tour.key!,
      payload.tour.version
    );
    dispatch(retrieveTourStatus(payload.userId));
    return result;
  }
);

export interface TourState {
  status?: Status;
}

export const initialState: TourState = {
  status: {
    tree: 0,
    home: 0,
    feed: 0,
    files: 0,
    subscription: 0,
  },
};

export const tourSlice = createSlice({
  name: "tour",
  initialState,
  reducers: {
    setTourStatuses: (state, action: PayloadAction<Status>) => {
      state.status = action.payload;
    },
    resetTour: (state) => {
      state = initialState;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(retrieveTourStatus.fulfilled, (state, action) => {
      state.status = action.payload;
    });
  },
});

export const selectTourStatus = (state: RootState) => state.tour.status;

export const { setTourStatuses, resetTour } = tourSlice.actions;

export default tourSlice.reducer;
