import axios, { AxiosError } from "axios";
import { FileDto, FileFilterDto } from "../components/files/Types";
import { API_URL } from "../Constants";
import { DUMMY_ALBUM_IDS } from "../pages/Folder";
import { AlbumInterface } from "../types/AlbumTypes";

const SHARE_FILES_URL = API_URL + "/graph/file/shareFiles";
const RETRIEVE_SHARED_FILES_URL = API_URL + "/graph/file/retrieveSharedFile";
const RETRIEVE_SHARED_RECEIVED_FILES_URL =
  API_URL + "/graph/file/retrieveSharedReceivedFile";

const SAVE_SHARED_FILES_URL = API_URL + "/graph/file/copySharedFiles";
const FIND_SHARED_FILES_WITH = API_URL + "/graph/file/findSharedFilesWith";
const UNSHARE_FILE_URL = API_URL + "/graph/file/unshareFile";

export const getUserFilesCount = async (uploader: string, safe?: boolean) => {
  let resp = await axios
    .get(API_URL + "/graph/file/retrieveUserFilesCount", {
      params: {
        uploader,
        safe,
      },
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
  if (resp?.data) {
    return resp.data as number;
  }
};
export const getRecentFiles = async (
  userId?: string,
  safe?: boolean,
  skip?: number,
  limit?: number
) => {
  let resp = await axios
    .get(API_URL + "/graph/file/retrieveAllMyFiles", {
      params: { userId, safe, skip, limit },
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
  console.log("The response of the files data is", resp);
  return resp?.data as FileDto[];
};

export const getDropboxFiles = async (personId?: string, folder?: string) => {
  let resp = await axios
    .get(API_URL + "/graph/file/retrieveExtFiles", {
      params: { personId, folder },
    })
    .catch((error: AxiosError) => {
      return error.response;
    });
  console.log("The response of the files data is", resp);
  return resp?.data as FileDto[];
};

export const getUserFiles = async (
  uploader: string,
  safe?: boolean,
  skip?: number,
  limit?: number
) => {
  let resp = await axios
    .get(API_URL + "/graph/file/retrieveUserFiles", {
      params: {
        uploader,
        safe,
        skip,
        limit,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
  }

  if (resp?.data && resp.data && Array.isArray(resp.data)) {
    return resp.data as FileDto[];
  }
};

export const retrieveFilesWithFilterCount = async (
  filter: FileFilterDto,
  safe?: boolean,
  type?: string | null
) => {
  const res = await axios.post(
    API_URL + "/graph/file/retrieveFilesCount",
    filter,
    {
      params: {
        safe,
        type,
      },
    }
  );
  if (res?.data) {
    return res.data as number;
  }
};

export const retrieveFilesWithFilter = async (
  filter: FileFilterDto,
  safe?: boolean,
  skip?: number,
  limit?: number,
  type?: string | null
) => {
  const res = await axios.post(API_URL + "/graph/file/retrieveFiles", filter, {
    params: {
      safe,
      skip,
      limit,
      type,
    },
  });
  console.log(res);

  if (res?.data && res.data && Array.isArray(res.data)) {
    return res.data as FileDto[];
  }
};

export const retrieveAlbum = async (
  userId: string,
  albumId: string,
  safe?: boolean,
  skip?: number,
  limit?: number
): Promise<AlbumInterface | null> => {
  const res = await axios.get(API_URL + "/graph/album/retrieveAlbum", {
    params: { userId, albumId, safe, skip, limit },
  });
  console.log(res);
  const album = res?.data as AlbumInterface;
  return album;
};

export const updateFileDto = async (userId: string, file: FileDto) => {
  const res = await axios.post(API_URL + "/graph/file/editFileMeta", file, {
    params: {
      userId,
    },
  });
  console.log(res);
  return res.status === 200;
};

export const retrieveFile = async (fileId: string) => {
  const res = await axios.get(API_URL + "/graph/file/retrieveFile", {
    params: {
      fileId,
    },
  });
  return res.data as FileDto;
};

export const moveFileFromToAlbum = async (
  userId: string,
  fileId: string,
  fromAlbumId: string,
  toAlbumId: string
) => {
  let removed = await removeFilesFromAlbum(userId, fromAlbumId, [fileId]);
  console.log(removed);
  if (removed) {
    let added = addFilesToAlbum(userId, toAlbumId, [fileId]).catch((e) => {
      //put it back!!!
      addFilesToAlbum(userId, fromAlbumId, [fileId]);
      return false;
    });
    return removed && added;
  }
  return false;
};

export const addFilesToAlbum = async (
  userId: string,
  albumId: string,
  fileIds: string[]
) => {
  const res = await axios.put(API_URL + "/graph/album/addFile", fileIds, {
    params: {
      userId,
      albumId,
    },
  });
  console.log(res);
  return res.status === 200;
};

export const removeFilesFromAlbum = async (
  userId: string,
  albumId: string,
  fileIds: string[]
) => {
  const res = await axios.put(API_URL + "/graph/album/removeFile", fileIds, {
    params: {
      userId,
      albumId,
    },
  });
  console.log(res);
  return res.status === 200;
};

export const uploadFile = async (
  file: any,
  uploader: string,
  tags?: string[],
  album?: string,
  description?: string,
  prot?: boolean,
  place?: string,
  timestamp?: string,
  latitude?: string,
  longitude?: string
) => {
  console.log(file);
  const formData = new FormData();
  formData.append("file", file);

  let params = {
    uploader,
    tags: tags ? tags.join(",") : "",
    album,
    description,
    isProtected: prot,
    userPic: false,
    place,
    timestamp,
    latitude,
    longitude,
  };

  let resp = await axios
    .post(API_URL + "/graph/file/upload", formData, {
      params,
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
  }

  return resp;
};

/**
 * upload profile pic for other peoples
 */
export const uploadPersonPic = async (
  file: any,
  uploader: string,
  person: string
) => {
  console.log(file);
  const formData = new FormData();
  formData.append("file", file);

  let resp = await axios
    .post(API_URL + "/graph/file/uploadPersonPic", formData, {
      params: {
        uploader,
        person,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
  }

  return resp;
};

/**
 * upload profile pic for current user
 */
export const uploadProfilePic = async (file: any, uploader: string) => {
  console.log(file);
  const formData = new FormData();
  formData.append("file", file);

  let resp = await axios
    .post(API_URL + "/graph/file/upload", formData, {
      params: {
        uploader,
        userPic: true,
      },
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
  }

  return resp;
};

export const retrieveUserTags = async (uploader: string) => {
  let resp = await axios
    .get(API_URL + "/graph/file/retieveUserTags", {
      params: {
        uploader,
      },
    })
    .catch((error) => {
      console.error(error);
      return error;
    });

  console.log(resp);
  if (resp?.status !== 200) {
    console.error(resp);
    return [];
    // throw new Error(resp?.statusText)
  } else if (resp.data) {
    return resp.data as { tagId: string; name: string }[];
  }
};
export const retrieveFileByTagsCount = async (
  uploader: string,
  tags: string[],
  safe?: boolean
) => {
  const res = await axios
    .post(API_URL + "/graph/file/retieveFileByTagsCount", tags, {
      params: { uploader, safe },
    })
    .catch((e) => {
      console.error(e);
      return e;
    });
  console.log(res);
  return res?.data as number;
};

export const retrieveFileByTags = async (
  uploader: string,
  tags: string[],
  safe?: boolean,
  skip?: number,
  limit?: number
) => {
  const res = await axios
    .post(API_URL + "/graph/file/retieveFileByTags", tags, {
      params: { uploader, safe, skip, limit },
    })
    .catch((e) => {
      console.error(e);
      return e;
    });
  console.log(res);
  const files = res?.data as FileDto[];
  return files;
};

export const retrieveTagAsAlbum = async (
  uploader: string,
  tag: string,
  isProtected = false,
  skip?: number,
  limit?: number
) => {
  let files = await retrieveFileByTags(
    uploader,
    [tag],
    isProtected,
    skip,
    limit
  );
  if (files && files.length > 0)
    return {
      albumId: DUMMY_ALBUM_IDS.TAG + tag,
      name: "#" + tag,
      files: files,
    } as AlbumInterface;
};

export const downloadFile = async (dto: FileDto, thumbnail = false) => {
  console.log(dto);
  let resp = await axios
    .get(API_URL + "/graph/file/downloadFile", {
      params: {
        fileId: dto.fileId,
        thumbnail,
      },
      responseType: "blob",
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
    return "";
  }

  var urlCreator = window.URL || window.webkitURL;
  var binaryData = [];
  binaryData.push(resp?.data);
  var imageUrl = urlCreator.createObjectURL(
    new Blob(binaryData) //, { type: "image/png" })
  );

  let tempLink = document.createElement("a");
  tempLink.href = imageUrl;
  tempLink.setAttribute("download", dto.name + "." + dto.extension);
  tempLink.click();

  return imageUrl;
};

export const deleteFiles = async (fileIds: string[]) => {
  let resp = await axios
    .delete(API_URL + "/graph/file/delete", {
      data: fileIds,
    })
    .catch((error: AxiosError) => {
      console.error(error);
      return error.response;
    });

  console.log(resp);

  if (resp?.status !== 200) {
    console.error(resp);
    // throw new Error(resp?.statusText)
  }

  return resp;
};

export const shareFilesAPI = async (
  userId: string,
  fileIds: (number | string)[]
) => {
  const res = await axios.post(SHARE_FILES_URL, fileIds, {
    params: { userId },
  });
  return res.data;
};

export const getSharedReceivedFilesAPICount = async (userId: string) => {
  const res = await axios.get(RETRIEVE_SHARED_RECEIVED_FILES_URL + "Count", {
    params: { userId },
  });
  console.log(res);
  return res.data as number;
};
export const getSharedReceivedFilesAPI = async (
  userId: string,
  skip?: number,
  limit?: number
) => {
  const res = await axios.get(RETRIEVE_SHARED_RECEIVED_FILES_URL, {
    params: { userId, skip, limit },
  });
  console.log(res);
  return res.data;
};

export const getSharedFilesAPICount = async (userId: string) => {
  const res = await axios.get(RETRIEVE_SHARED_FILES_URL + "Count", {
    params: { userId },
  });
  console.log(res);
  return res.data as number;
};
export const getSharedFilesAPI = async (
  userId: string,
  skip?: number,
  limit?: number
) => {
  const res = await axios.get(RETRIEVE_SHARED_FILES_URL, {
    params: { userId, skip, limit },
  });
  console.log(res);
  return res.data;
};

export const copySharedFiles = async (userId: string, fileIds: string[]) => {
  const res = await axios.post(SAVE_SHARED_FILES_URL, fileIds, {
    params: { userId },
  });
  console.log(res);

  //return res.status === 200;
  return new Map(Object.entries(res.data as Map<string, FileDto>));
};

export const findSharedFilesWith = async (
  sourceId: string,
  targetId: string,
  skip?: number,
  limit?: number
) => {
  const res = await axios.get(FIND_SHARED_FILES_WITH, {
    params: { sourceId, targetId, skip, limit },
  });

  console.log(res);
  return res.data as FileDto[];
};

export const getFindSharedFilesWithAPICount = async (
  sourceId: string,
  targetId: string
) => {
  const res = await axios.get(FIND_SHARED_FILES_WITH + "Count", {
    params: { sourceId, targetId },
  });
  console.log(res);
  return res.data as number;
};
export const unshareFileAPI = async (
  uploaderId: string,
  fileId: string,
  groupId: string
): Promise<string> => {
  return await axios.delete(UNSHARE_FILE_URL, {
    params: { uploaderId, groupId, fileId },
  });
};
