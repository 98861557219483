import React, { FunctionComponent, useEffect } from "react";
import { Route, RouteProps, Redirect } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  retrieveNotifications,
  selectAuthenticated,
  selectProfile,
} from "../store/auth/authSlice";
import { ROUTE_LOGIN } from "../Constants";
import { HistoryListener } from "../FirebaseConfig";
import { hideCaptchaBanner } from "../components/CaptchaBanner";

/**
 * Will redirect to "/login" if not authenticated
 */
const PrivateRoute: FunctionComponent<RouteProps> = ({
  component,
  ...rest
}) => {
  const params = new URLSearchParams(window.location.search);

  const isAuthenticated =
    useAppSelector(selectAuthenticated) || !!params.get("token");

  const userId = useAppSelector(selectProfile)?.personId;
  const dispatch = useAppDispatch();
  const MINUTE_MS = 1 * 60000;

  hideCaptchaBanner();

  useEffect(() => {
    if (!isAuthenticated) return;

    const interval = setInterval(() => {
      dispatch(retrieveNotifications(userId!));
    }, MINUTE_MS);

    return () => clearInterval(interval);
  }, []);

  if (!component) return null;

  const Component: React.ElementType = component;

  return (
    <>
      <HistoryListener />
      <Route
        {...rest}
        render={({ location }) =>
          isAuthenticated ? (
            <Component />
          ) : (
            <Redirect
              to={{ pathname: ROUTE_LOGIN, state: { from: location } }}
            />
          )
        }
      />
    </>
  );
};

export default PrivateRoute;
