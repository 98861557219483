import axios from "axios";
import { FileDto } from "../components/files/Types";
import { API_URL } from "../Constants";
import { GroupInterface, OneToOneInterface } from "../types/GroupTypes";
import { MOCK_USER } from "./feedAPI";

/*
getAllUserGroup - /api/graph/group/getAllUserGroup [GET]
Servizio che accetta un parametro:
-- userId: rappresenta l'id dell'utente che effettua la richiesta
*/

const CREATE_GROUP_URL = API_URL + "/graph/group/createGroup"; //POST
const DELETE_GROUP_URL = API_URL + "/graph/group/deleteGroup"; //DELETE
const RETRIEVE_GROUP_URL = API_URL + "/graph/group/retrieveGroup"; //GET
const ADD_MEMBER_URL = API_URL + "/graph/group/addMember"; //PUT
const REMOVE_MEMBER_URL = API_URL + "/graph/group/removeMember"; //PUT
const RETRIEVE_USER_GROUPS_URL = API_URL + "/graph/group/retrieveAllUserGroup"; //GET
const RENAME_GROUP_URL = API_URL + "/graph/group/renameGroup"; //PUT
const ADD_ADMIN_URL = API_URL + "/graph/group/addAdmin"; //PUT
const REMOVE_ADMIN_URL = API_URL + "/graph/group/removeAdmin"; //PUT

interface GroupCreationInterface {
  name: string;
  administrators?: { personId: number }[];
  members?: { personId: number | string }[];
  groupPic?: FileDto;
}

export const createGroupAPI = async (
  creatorId: string,
  group: GroupCreationInterface
): Promise<GroupInterface> => {
  return (await axios.post(CREATE_GROUP_URL, group, { params: { creatorId } }))
    .data;
};

export const deleteGroupAPI = async (userId: string, groupId: string) => {
  return (await axios.delete(DELETE_GROUP_URL, { params: { userId, groupId } }))
    .data;
};

export const retrieveGroupAPI = async (
  userId: string,
  groupId: string
): Promise<GroupInterface | OneToOneInterface> => {
  return (await axios.get(RETRIEVE_GROUP_URL, { params: { userId, groupId } }))
    .data;
};

export const addGroupMemberAPI = async (
  creatorId: string,
  groupId: string,
  memberIds: (number | string)[]
): Promise<GroupInterface> => {
  return (
    await axios.put(ADD_MEMBER_URL, memberIds, {
      params: { userId: creatorId, groupId },
    })
  ).data;
};

export const removeGroupMemberAPI = async (
  creatorId: string,
  groupId: string,
  memberIds: (number | string)[]
): Promise<GroupInterface> => {
  return (
    await axios.put(REMOVE_MEMBER_URL, memberIds, {
      params: { userId: creatorId, groupId },
    })
  ).data;
};

export interface ConnectionsDTO {
  groups: GroupInterface[];
  friends: OneToOneInterface[];
  numberOfGroups: number;
  numberOfFriends: number;
}

export const retrieveUserGroupsAPI = async (
  userId: string
): Promise<ConnectionsDTO> => {
  let resp = await axios.get(RETRIEVE_USER_GROUPS_URL, { params: { userId } });
  console.log(resp);
  return resp.data;
};

export const renameGroupAPI = async (
  userId: string,
  groupId: string,
  name: string
): Promise<GroupInterface> => {
  return await axios.put(RENAME_GROUP_URL, null, {
    params: { userId, groupId, name },
  });
};

export const addAdminAPI = async (
  userId: number | string,
  groupId: number | string,
  memberIds: (number | string)[]
): Promise<GroupInterface> => {
  return (
    await axios.put(ADD_ADMIN_URL, memberIds, {
      params: { userId, groupId },
    })
  ).data;
};

export const removeAdminAPI = async (
  userId: number | string,
  groupId: number | string,
  memberIds: (number | string)[]
): Promise<GroupInterface> => {
  return (
    await axios.put(REMOVE_ADMIN_URL, memberIds, {
      params: { userId, groupId },
    })
  ).data;
};

export const MOCK_GROUP: GroupInterface = {
  name: "Gruppo A",
  administrators: [MOCK_USER],
  members: [MOCK_USER],
  groupId: "123",
  size: 2,
  creationDate: "21/09/2021",
  pendingPost: "1",
  type: "MULTI",
};
