import React, { useEffect, useState } from "react";
import { Button, Form, Input, Modal } from "antd";
import { AlbumInterface, getLabelOf } from "../../types/AlbumTypes";

interface AlbumModalProps {
  album?: AlbumInterface;

  onClose: () => void;
  open: boolean;

  onSubmit: (name: string) => void;
}

const AlbumModal: React.FunctionComponent<AlbumModalProps> = ({
  album,
  open,
  onClose,
  onSubmit,
}) => {
  const [name, setName] = useState("Test");
  useEffect(() => {
    if (album) {
      setName(getLabelOf(album));
    } else {
      setName("");
    }
  }, [open]);

  const onSubmitOverride = (values: { name: string }) => {
    onSubmit(values.name);
  };

  return (
    <Modal
      visible={open}
      zIndex={1003}
      footer={false}
      centered
      destroyOnClose
      maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
      bodyStyle={{ padding: 40 }}
      onCancel={onClose}
      width={"fit-content"}
    >
      <Form onFinish={onSubmitOverride} layout="vertical">
        <Form.Item name="name" label="Nome Album" rules={[{ required: true }]}>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
        </Form.Item>
        <div className="modal-footer-action">
          <Form.Item>
            <Button htmlType="button" onClick={onClose}>
              Annulla
            </Button>
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ marginLeft: 20 }}>
              Crea Album
            </Button>
          </Form.Item>
        </div>
      </Form>
    </Modal>
  );
};

export default AlbumModal;
