import {
  EditOutlined,
  DeleteOutlined,
  DownloadOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";
import React from "react";
import { FileDto } from "./Types";

export function FileActionButtons(props: {
  file: FileDto;
  selectFile?: () => void;
  downloadFile?: () => void;
  openShareModal?: () => void;
  readOnly?: boolean;
  openDeleteModal?: () => void;
}) {
  return (
    <div className="action-buttons-container">
      {
        <Tooltip title={"Modifica"}>
          <div className="action-button" onClick={props.selectFile}>
            <EditOutlined
              style={{
                width: 15,
                height: 15,
                padding: 1,
                color: "purple",
              }}
            />
          </div>
        </Tooltip>
      }
      {!props.file.isProtected && !props.readOnly ? (
        <Tooltip title={props.file.sharedToPerson ? "Condiviso" : "Condividi"}>
          <div className="action-button" onClick={props.openShareModal}>
            <ShareAltOutlined
              style={{
                width: 15,
                height: 15,
                padding: 1,
                color: "purple",
              }}
            />
          </div>
        </Tooltip>
      ) : (
        props.file.uploadedBy && (
          <div
            className="hidelongtext"
            style={{
              textAlign: "center",
            }}
          >
            {`Condiviso da`}
            <br />
            {`${props.file.uploadedBy.uploader.name} ${props.file.uploadedBy.uploader.surname} `}
          </div>
        )
      )}

      {!props.readOnly && (
        <Tooltip title={"Elimina"}>
          <div className="action-button" onClick={props.openDeleteModal}>
            <DeleteOutlined
              style={{
                width: 15,
                height: 15,
                padding: 1,
                color: "purple",
              }}
            />
          </div>
        </Tooltip>
      )}
      <Tooltip title={"Download"}>
        <div className="action-button" onClick={props.downloadFile}>
          <DownloadOutlined
            style={{
              width: 15,
              height: 15,
              padding: 1,
              color: "purple",
            }}
          />
        </div>
      </Tooltip>
    </div>
  );
}
