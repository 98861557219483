import {
  GenericGroupInterface,
  GroupInterface,
  OneToOneInterface,
} from "../types/GroupTypes";
import _ from "lodash";

export const buildGenericGroupList = (
  multiGroups: GroupInterface[],
  otoGroups: OneToOneInterface[],
  userId: string
): GenericGroupInterface[] =>
  multiGroups
    .map((g) => parseMultiGroup(g))
    .concat(otoGroups.map((g) => parseOneToOneGroup(g, userId)));

export const parseMultiGroup = (g: GroupInterface): GenericGroupInterface => ({
  ..._.pick(g, [
    "groupId",
    "name",
    "creationDate",
    "groupPic",
    "pendingPost",
    "lastPost",
  ]),
  type: "MULTI",
  members: g.administrators
    .map((a) => ({ ...a, admin: true }))
    .concat(g.members.map((m) => ({ ...m, admin: false }))),
});

export const parseOneToOneGroup = (
  g: OneToOneInterface,
  userId: string
): GenericGroupInterface => ({
  groupId: g.groupId,
  thumbnailUri:
    g.source.personId === userId ? g.target.pictureUri : g.source.pictureUri,
  name:
    g.source.personId === userId
      ? `${g.target.name} ${g.target.surname}`
      : `${g.source.name} ${g.source.surname}`,
  ..._.pick(g, ["creationDate", "pendingPost", "lastPost"]),
  type: "FRIENDSHIP",
  members: [
    { ...g.source, admin: true },
    { ...g.target, admin: true },
  ],
});
