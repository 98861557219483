import { Button, Input, message, Modal, Progress, Select, Tooltip } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { useEffect, useState } from "react";
import { uploadFile } from "../../service/fileAPI";
import {
  retrieveStorage,
  selectAlbums,
  selectProfile,
  selectTags,
  updateAlbums,
  updateTags,
} from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  readExifData,
  chooseIconByFileExt,
  splitNameExtension,
} from "../../util/FileUtil";

import TextArea from "rc-textarea";
import { SafeImage } from "../SafeImage";
import {
  DeleteOutlined,
  FileOutlined,
  FolderAddOutlined,
} from "@ant-design/icons";
import { COLORS } from "../../Constants";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import AlbumModal from "./AlbumModal";
import { createAlbumAPI } from "../../service/albumAPI";
import { toast } from "react-toastify";

interface SuperFile {
  file: RcFile;
  src: string;
}

interface FileInfo {
  file?: RcFile;
  uid?: string;
  name?: string;
  description?: string;
  album?: string;
  tag: string[];
  tagSearch?: string;
  src?: string;
  ext?: string;
}

const { Option } = Select;

export interface MultipleFileFormProps {
  onClose: (update: boolean) => void;
  onRemoveFile: (uid: string) => void;
  directoryName?: string;
  currentAlbum?: string;
  currentfileList?: SuperFile[];
  onChangeModal: Function;
  modalListVisible: boolean;
  onCancel: () => void;
}

export const MultipleFileForm: React.FunctionComponent<
  MultipleFileFormProps
> = (props) => {
  const [fileInfos, setFileInfos] = useState<Map<string, FileInfo>>(new Map());
  const [fileState, setFileState] = useState<string>();

  const dispatch = useAppDispatch();

  const [loading, setloading] = useState(false);

  const [listAlbum, setListAlbum] = useState<string>();
  const [newAlbumFromDirectory, setnewAlbumFromDirectory] = useState(false);

  const [isCreatingAlbum, setisCreatingAlbum] = useState(false);
  const [newAlbumName, setNewAlbumName] = useState<string>();

  const [uploadPerc, setUploadPerc] = useState<number>(0);
  const [errorPerc, setErrorPErc] = useState<number>(0);

  const tags = useAppSelector(selectTags);
  const userAlbums = useAppSelector(selectAlbums);

  const [index, setIndex] = useState(0);

  const [count, setCount] = useState(1);

  const RightClick_Arrow = () => {
    if (index !== props.currentfileList!.length - 1) {
      var res;
      res = index + 1;
      setIndex(res);
      setCount(count + 1);
      var keyFind = props.currentfileList![res].file.uid;
      setFileState(keyFind);
    }
  };

  const LeftClick_Arrow = () => {
    if (index !== 0) {
      var res;
      res = index - 1;
      setIndex(res);
      setCount(count - 1);
      var keyFind = props.currentfileList![res].file.uid;
      setFileState(keyFind);
    }
  };

  useEffect(() => {
    setListAlbum(
      props.currentAlbum && isNaN(parseInt(props.currentAlbum))
        ? undefined
        : props.currentAlbum
    );
  }, [props.currentAlbum]);

  useEffect(() => {
    if (props.directoryName) {
      setnewAlbumFromDirectory(true);
    }
  }, [props.directoryName]);

  useEffect(() => {
    if (props.currentfileList?.length === 0) {
      props.onCancel();
      return;
    }
    let tempfilesMap = new Map(fileInfos);
    tempfilesMap.clear();
    var len = props.currentfileList!.length;
    if (len == 0) {
      console.log("leng = 0");
    } else {
      console.log(listAlbum);
      var keyFind = props.currentfileList![index].file.uid;
      setFileState(keyFind);
      for (var i = 0; i < len; i++) {
        tempfilesMap.set(props.currentfileList![i].file.uid, {
          uid: props.currentfileList![i].file.uid,
          name: props.currentfileList![i].file.name,
          file: props.currentfileList![i].file,
          description: undefined,
          src: props.currentfileList![i].src,
          album: listAlbum,
          tag: [],
        });
      }
    }
    setFileInfos(tempfilesMap);
    console.log("File Infos Console", fileInfos);
  }, [props.currentfileList!]);

  const userId = useAppSelector(selectProfile)?.personId.toString()!;

  useEffect(() => {
    if (newAlbumName) {
      setListAlbum(userAlbums?.find((a) => a.name === newAlbumName)?.albumId);
    }
  }, [userAlbums]);

  const createNewAlbum = (name: string) => {
    setloading(true);
    setNewAlbumName(name);
    setisCreatingAlbum(false);
    createAlbumAPI(userId!, name)
      .then(() => dispatch(updateAlbums()))
      .catch((err) => console.log(err))
      .finally(() => setloading(false));
  };

  const uploadFiles = async () => {
    setUploadPerc(0);
    setErrorPErc(0);
    let uploadedIds: string[] = [];

    setloading(true);

    await uploadWithProgress(fileInfos, (p, e, id) => {
      setUploadPerc(Math.round(p));
      setErrorPErc(Math.round(e));

      if (id) {
        uploadedIds.push(id);
      }

      console.log(p, e);
      console.log(uploadedIds);

      if (p === 100) {
        let newInfos = new Map(fileInfos);
        uploadedIds.forEach((id) => {
          newInfos.delete(id);
        });
        setFileInfos(newInfos);

        message.success(`${uploadedIds.length} files caricati!`);

        toast.success(`${uploadedIds.length} files caricati!`, {
          position: "bottom-right",
          hideProgressBar: true,
        });

        if (e !== 0)
          // message.error("Alcuni files non sono stati caricati, riprova!", 5);
          toast.error("Alcuni files non sono stati caricati, riprova!", {
            position: "bottom-right",
            hideProgressBar: true,
          });
        else {
          props.onChangeModal(false);
          props.onClose(true);
        }
      }
    });
    setloading(false);
  };

  const uploadWithProgress = async (
    fileInfos: Map<string, FileInfo>,
    onProgress: (v: number, e: number, id: string | undefined) => void
  ) => {
    let d = 0;
    let e = 0;

    let list = Array.from(fileInfos);
    for (let i = 0; i < list.length; i++) {
      let w = list[i];

      if (w[1].file != null && w[1].name) {
        let file = new File([w[1].file], w[1].name, {
          type: w[1].file?.type,
        });

        // add some delay between uploads
        await new Promise((r) => setTimeout(r, 100));

        let exifData = await readExifData(file);

        const res = await uploadFile(
          file,
          userId,
          w[1].tag,
          listAlbum,
          w[1].description,
          undefined,
          undefined,
          exifData?.timestamp,
          exifData?.latitude,
          exifData?.longitude
        );

        d++;
        if (res?.status !== 200 && res?.data.message) {
          e++;
          onProgress(
            (d * 100) / fileInfos.size,
            (e * 100) / fileInfos.size,
            undefined
          );
        } else {
          onProgress(
            (d * 100) / fileInfos.size,
            (e * 100) / fileInfos.size,
            w[1].uid
          );
        }
      }
    }
    dispatch(updateTags());
    dispatch(retrieveStorage());
  };

  const k = fileState;
  const f = fileInfos.get(k ?? " ");
  console.log("Going to console K", k);

  return (
    <>
      <LoadingOverlayWrapper
        styles={{
          wrapper: { display: "contents" },
        }}
        active={loading}
        spinner
        className="loader"
      >
        <Modal
          zIndex={799}
          visible={props.modalListVisible}
          footer={[
            <div style={{ display: "flex" }}>
              <Select
                placeholder="Seleziona Album"
                style={{ width: 150 }}
                value={listAlbum}
                onChange={(value) => {
                  let album: string = value as string;
                  console.log(album);
                  setListAlbum(album);
                }}
              >
                {userAlbums?.map((album) => (
                  <Option key={album.albumId} value={album.albumId}>
                    {album.name}
                  </Option>
                ))}
              </Select>
              <Tooltip title="Nuovo Album">
                <div
                  style={{
                    cursor: "pointer",
                    padding: 4,
                  }}
                  onClick={() => {
                    setisCreatingAlbum(true);
                  }}
                >
                  <FolderAddOutlined style={{ fontSize: 25 }} />
                </div>
              </Tooltip>
            </div>,
            <div>
              <Button
                type="primary"
                onClick={() => {
                  uploadFiles();
                }}
              >
                carica
              </Button>
            </div>,
            <div>
              <Button
                onClick={() => {
                  setFileInfos(new Map());
                  props.onCancel();
                }}
              >
                annulla
              </Button>
            </div>,
          ]}
          onCancel={() => {
            setFileInfos(new Map());
            props.onCancel();
          }}
          destroyOnClose
          maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
          title="Aggiungi"
          width={"fit-content"}
          style={{ minWidth: 350 }}
          bodyStyle={{ padding: "5px 30px 30px" }}
        >
          <div className="multiple-file-form">
            {uploadPerc !== 0 && uploadPerc !== 1 && (
              <Progress
                strokeColor={COLORS.neonGreen}
                percent={uploadPerc}
                success={{ percent: errorPerc, strokeColor: "red" }}
                showInfo={true}
              />
            )}

            <div
              style={{
                marginTop: "30px",
                overflow: "scroll",
                maxHeight: "45vh",
                gap: 10,
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginBottom: "5px",
                  gap: "20px",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                key={k}
              >
                <h1 style={{ paddingTop: 80 }}>
                  {count}/{props.currentfileList!.length}
                </h1>
                <div className="wrap_columns">
                  <LeftOutlined onClick={LeftClick_Arrow} />

                  <SafeImage
                    preview
                    width={100}
                    height={100}
                    style={{
                      borderRadius: "5%",
                      height: 100,
                      width: 300,
                      objectFit: "cover",
                    }}
                    src={f?.src}
                    fallback={
                      <div
                        style={{ width: "100%", height: 300 }}
                        className="centered"
                      >
                        {chooseIconByFileExt(
                          splitNameExtension(f?.file?.name)[1],
                          60
                        )}
                      </div>
                    }
                  />
                  <RightOutlined onClick={RightClick_Arrow} />
                </div>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 10,
                    width: 300,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      gap: 15,
                    }}
                  >
                    <Input
                      size={"small"}
                      placeholder="Nome"
                      defaultValue={splitNameExtension(f?.name)[0]}
                      onChange={(e) => {
                        let newInfos = new Map(fileInfos);
                        let newFileInfo: FileInfo = newInfos.get(f?.uid!) ?? {
                          tag: [],
                        };
                        newFileInfo.name =
                          e.target.value + "." + splitNameExtension(f?.name)[1];
                        newInfos.set(f?.uid!, newFileInfo);
                        console.log(newInfos);
                        setFileInfos(newInfos);
                        console.log(fileInfos);
                      }}
                    />
                    <div style={{ maxWidth: 60 }}>
                      <Input
                        disabled
                        defaultValue={splitNameExtension(f?.name)[1]}
                      />
                    </div>
                  </div>

                  <div>
                    <Select
                      style={{ width: "100%" }}
                      mode="tags"
                      placeholder="Tags"
                      allowClear
                      onSelect={(v) => {
                        let newInfos = new Map(fileInfos);
                        let newFileInfo: FileInfo = newInfos.get(f?.uid!) ?? {
                          tag: [],
                        };
                        if (v?.toString != null)
                          newFileInfo.tag = [...newFileInfo.tag, v?.toString()];
                        newFileInfo.tagSearch = "";
                        newInfos.set(f?.uid!, newFileInfo);
                        setFileInfos(newInfos);
                      }}
                      value={fileInfos.get(f?.uid!)?.tag}
                      onSearch={(v) => {
                        let newInfos = new Map(fileInfos);
                        let newFileInfo: FileInfo = newInfos.get(f?.uid!) ?? {
                          tag: [],
                        };
                        newFileInfo.tagSearch = v?.toString();
                        newInfos.set(f?.uid!, newFileInfo);
                        setFileInfos(newInfos);

                        let currentValue = fileInfos.get(f?.uid!)?.tag;

                        let separators = [","];

                        separators.forEach((sep) => {
                          if (v.endsWith(sep)) {
                            if (currentValue) {
                              let newInfos = new Map(fileInfos);
                              let newFileInfo: FileInfo = newInfos.get(
                                f?.uid!
                              ) ?? {
                                tag: [],
                              };
                              newFileInfo.tag = [
                                ...currentValue,
                                v.substr(0, v.indexOf(sep)),
                              ];
                              newInfos.set(f?.uid!, newFileInfo);
                              setFileInfos(newInfos);
                            } else {
                              let newInfos = new Map(fileInfos);
                              let newFileInfo: FileInfo = newInfos.get(
                                f?.uid!
                              ) ?? {
                                tag: [],
                              };
                              newFileInfo.tag = [v.substr(0, v.indexOf(sep))];
                              newInfos.set(f?.uid!, newFileInfo);
                              setFileInfos(newInfos);
                            }
                            let newInfos = new Map(fileInfos);
                            let newFileInfo: FileInfo = newInfos.get(
                              f?.uid!
                            ) ?? {
                              tag: [],
                            };
                            newFileInfo.tagSearch = "";
                            newInfos.set(f?.uid!, newFileInfo);
                            setFileInfos(newInfos);
                          }
                        });
                      }}
                      searchValue={fileInfos.get(f?.uid!)?.tagSearch}
                    >
                      {tags?.map((tag) => (
                        <Option key={tag.tagId} value={tag.name}>
                          {tag.name}
                        </Option>
                      ))}
                    </Select>
                  </div>

                  <div style={{ display: "flex" }}>
                    <TextArea
                      rows={3}
                      style={{ width: "100%" }}
                      placeholder="Descrizione"
                      onChange={(e) => {
                        let newInfos = new Map(fileInfos);
                        let newFileInfo: FileInfo = newInfos.get(f?.uid!) ?? {
                          tag: [],
                        };
                        newFileInfo.description = e.target.value;
                        newInfos.set(f?.uid!, newFileInfo);
                        setFileInfos(newInfos);
                      }}
                    />
                  </div>
                </div>
                <div>
                  <DeleteOutlined onClick={() => props.onRemoveFile(f?.uid!)} />
                </div>
              </div>
            </div>
          </div>

          <Modal
            visible={newAlbumFromDirectory}
            zIndex={1003}
            title={"Crea album"}
            onOk={() => {
              setNewAlbumName(props.directoryName);
              createNewAlbum(props.directoryName!);
              setnewAlbumFromDirectory(false);
            }}
            onCancel={() => {
              setnewAlbumFromDirectory(false);
            }}
          >
            {`Vuoi creare un nuovo album con il nome"${props.directoryName}"?`}
          </Modal>
          <AlbumModal
            open={isCreatingAlbum}
            onClose={() => setisCreatingAlbum(false)}
            onSubmit={createNewAlbum}
          />
        </Modal>
      </LoadingOverlayWrapper>
    </>
  );
};
