import { Tour } from "./types";

export const filesTour: Tour = {
  key: "files",
  version: 1, // increase on changes to make users re-do the tour
  steps: [
    {
      disableBeacon: true,
      target: ".add-document-btn",
      content: "Qui potrai caricare nuovi documenti",
    },
    {
      target: ".drawer.cell.all",
      content: "In questa cartella troverai tutti i tuoi documenti",
    },
    {
      target: ".drawer.cell.desk",
      content: "Qui invece troverai i documenti non categorizzati",
    },
    {
      target: ".drawer.cell.shared",
      content:
        "I documenti condivisi dai tuoi familiari saranno accessibili qui",
    },
    {
      target: ".search-bar",
      content:
        "Puoi cercare i tuoi files per TAG assegnato e per data di creazione",
    },
    {
      target: ".safe-btn",
      content:
        "I tuoi files protetti saranno qui. Non saranno condivisi con nessuno",
    },
  ],
};
