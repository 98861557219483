import React from "react";
import { Button, Modal } from "antd";

interface ConfirmationModalProps {
  visible?: boolean;
  message?: string;
  cancelLabel?: string;
  confirmLabel?: string;

  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmationModal: React.FunctionComponent<ConfirmationModalProps> = ({
  visible,
  children,
  message,
  cancelLabel = "Annulla",
  confirmLabel = "Conferma",
  onCancel,
  onConfirm,
}) => {
  return (
    <Modal
      visible={visible}
      footer={[
        <Button onClick={onCancel}>{cancelLabel}</Button>,
        <Button type="primary" onClick={onConfirm}>
          {confirmLabel}
        </Button>,
      ]}
      centered
      destroyOnClose
      maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
      bodyStyle={{ padding: 40 }}
      onCancel={onCancel}
      width={"fit-content"}
    >
      {message ? <p>{message}</p> : children}
    </Modal>
  );
};

export default ConfirmationModal;
