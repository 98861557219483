import {
  AppstoreOutlined,
  FileOutlined,
  LeftOutlined,
  RightOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { Button, Input, Modal, Form, message, Pagination } from "antd";
import Search from "antd/lib/transfer/search";
import { filter, toLength } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import ConfirmationModal from "../components/ConfirmationModal";
import CreatePostFromFilesModal from "../components/CreatePostFromFilesModal";
import AlbumModal from "../components/files/AlbumModal";
import { Drawer } from "../components/files/Drawer";
import FileForm, { FileFormHandler } from "../components/files/FileForm";
import FileItem from "../components/files/FileItem";
import { FilesHeader } from "../components/files/FilesHeader";
import ShareAlbumModal from "../components/files/ShareAlbumModal";
import { FileDto, FileFilterDto } from "../components/files/Types";
import UploadManager from "../components/files/UploadManager";
import { SafeImage } from "../components/SafeImage";
import { isPersonDto, PersonDto } from "../components/tree/TreeTypes";

import { COLORS, ROUTE_FILES, ROUTE_FOLDER } from "../Constants";
import MainLayout from "../layouts/MainLayout";
import {
  createAlbumAPI,
  deleteAlbumAPI,
  renameAlbumAPI,
  retrieveSharedAlbumAPI,
  shareAlbumAPI,
  shareAlbumsAPI,
  unshareAlbumAPI,
} from "../service/albumAPI";
import {
  addMultiplePostAPI,
  addPostAPI,
  MultiplePostCreationInterface,
  PostCreationInterface,
} from "../service/feedAPI";
import {
  deleteFiles,
  downloadFile,
  getSharedFilesAPI,
  getUserFiles,
  retrieveAlbum,
  shareFilesAPI,
  retrieveTagAsAlbum,
  retrieveFilesWithFilter,
  getUserFilesCount,
  retrieveFilesWithFilterCount,
  getSharedFilesAPICount,
  retrieveFileByTagsCount,
  retrieveFile,
  findSharedFilesWith,
  getFindSharedFilesWithAPICount,
  unshareFileAPI,
  getSharedReceivedFilesAPICount,
  getSharedReceivedFilesAPI,
} from "../service/fileAPI";
import { getUserProfileById } from "../service/userAPI";
import {
  retrieveStorage,
  selectProfile,
  updateAlbums,
} from "../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { AlbumInterface, getLabelOf } from "../types/AlbumTypes";
import { logCurrent } from "../util/Logger";
import { FileActionButtons } from "../components/files/FileActionButtons";
export interface FolderProps {}

export enum DUMMY_ALBUM_IDS {
  ALL = "all",
  SHARED = "shared",
  SHARED_ALL = "shared_all",
  TAG = "tag_",
  SAFE = "safe",
  TEMP = "temp",
  ANNUAL = "annual",
  PLACE = "place",
}

const Folder: React.FunctionComponent<FolderProps> = (props) => {
  const [showFileViewerModal, setShowFileViewerModal] = useState(false);
  const [index, setindex] = useState(0);
  const [selectImage, setselectImage] = useState(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [album, setAlbum] = useState<AlbumInterface>();
  const [filesCount, setFilesCount] = useState<number | undefined>();
  const [isCreatingAlbum, setIsCreatingAlbum] = useState(false);
  const [gridMode, setgridMode] = useState(false);
  const [fileToDelete, setFileToDelete] = useState<FileDto>();
  const [selectedFile, setSelectedFile] = useState<FileDto>();
  const [sharedFiles, setSharedFiles] = useState<FileDto[]>();
  const [multiSelection, setMultiSelection] = useState<{
    [key: string]: FileDto | undefined;
  }>({});
  const [editingAlbum, setEditingAlbum] = useState<
    "delete" | "rename" | "share" | null
  >();
  const [readOnly, setReadOnly] = useState(false);

  const [readOnlyShared, setReadOnlyShared] = useState(true);
  const history = useHistory();

  const dispatch = useAppDispatch();

  const userId = useAppSelector(selectProfile)?.personId;

  var folderId = decodeURI(window.location.pathname);
  folderId = folderId.substring(folderId.lastIndexOf("/") + 1);

  const params = new URLSearchParams(window.location.search);
  const safe: boolean = params.get("isProtected") === "true";
  const fileId: string | null = params.get("fileId");

  const [copiedFile, setCopiedFile] = useState<FileDto>();

  const isMyFile = (file: FileDto) => {
    var sharedToPerson = file.sharedToPerson as any[];
    return sharedToPerson?.some((e) => e.person?.personId === userId);
  };
  const { id } = useParams<{ id?: string }>();

  const page: number = params.get("page") ? Number(params.get("page")) : 1;
  const filesPerPage: number = params.get("filesPerPage")
    ? Number(params.get("filesPerPage"))
    : 5;

  useEffect(() => {
    if (fileId) openFile(fileId);
  }, [fileId]);

  const openFile = async (fileId: string) => {
    if (fileId) {
      let dto = await retrieveFile(fileId);
      setSelectedFile(dto);
    }
  };

  useEffect(() => {
    loadFiles();
  }, [page, filesPerPage, id]);

  const loadFiles = async () => {
    let skip: number = 0;
    let limit: number = filesPerPage;

    if (page > 1) {
      skip = filesPerPage * (page - 1);
    }

    setLoading(true);
    if (folderId.startsWith(DUMMY_ALBUM_IDS.ALL)) {
      let filterStr = params.get("filter");
      if (!filterStr) {
        setFilesCount(await getUserFilesCount(userId!, safe));
        getUserFiles(userId!, safe, skip, limit).then((files) => {
          if (files)
            setAlbum({
              name: "TUTTI",
              files: files,
              albumId: "",
            });
          setLoading(false);
        });
      } else {
        let filter = JSON.parse(filterStr) as FileFilterDto;
        let types = params.get("fileTypes");
        if (filter) {
          filter.isProtected = safe;
          filter.uploader = [userId!];
          setFilesCount(
            await retrieveFilesWithFilterCount(filter, safe, types)
          );
          retrieveFilesWithFilter(filter, safe, skip, limit, types).then(
            (files) => {
              if (files)
                setAlbum({
                  name: "RICERCA",
                  files: files,
                  albumId: "",
                });
              setLoading(false);
            }
          );
        }
      }
    } else if (folderId === DUMMY_ALBUM_IDS.SHARED) {
      let targetId = params.get("targetId");
      let targetName = params.get("targetName");

      if (targetId && targetName) {
        setFilesCount(await getFindSharedFilesWithAPICount(userId!, targetId));

        findSharedFilesWith(userId!, targetId, skip, limit)
          .then((files) => {
            setReadOnlyShared(true);

            if (files) {
              setAlbum({
                name: "CONDIVISI CON " + targetName,
                files,
                albumId: "",
              });
            }
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        console.log("ALTRO");
        setFilesCount(await getSharedFilesAPICount(userId!));
        getSharedFilesAPI(userId!, skip, limit)
          .then((files) => {
            console.log(files);
            setReadOnly(true);
            if (files)
              setAlbum({ name: "CONDIVISI CON ME", files, albumId: "" });
          })
          .finally(() => {
            setLoading(false);
          });
      }
    } else if (folderId === DUMMY_ALBUM_IDS.SHARED_ALL) {
      setFilesCount(await getSharedReceivedFilesAPICount(userId!));
      getSharedReceivedFilesAPI(userId!, skip, limit)
        .then((files) => {
          console.log(files);
          setReadOnlyShared(true);
          if (files) setAlbum({ name: "CONDIVISI", files, albumId: "" });
        })
        .finally(() => {
          setLoading(false);
        });
    } else if (folderId === DUMMY_ALBUM_IDS.SAFE) {
      setAlbum({ albumId: "", name: "CASSAFORTE", files: [] });
      setLoading(false);
    } else if (folderId.startsWith(DUMMY_ALBUM_IDS.TAG)) {
      setFilesCount(
        await retrieveFileByTagsCount(
          userId!,
          [folderId.substring(folderId.indexOf("_") + 1)],
          safe
        )
      );
      retrieveTagAsAlbum(
        userId!,
        folderId.substring(folderId.indexOf("_") + 1),
        safe,
        skip,
        limit
      )
        .then((a) => {
          if (a) {
            setAlbum({
              name: a.name,
              albumId: a.albumId,
              creationDate: a.creationDate,
              files: a.files,
              albumSharedToPerson: a.albumSharedToPerson,
            });
          }
          console.log(a);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else if (folderId.startsWith(DUMMY_ALBUM_IDS.ANNUAL)) {
      let split = folderId.split("_");
      let year = Number.parseInt(split[2]);
      let month = Number.parseInt(split[1]) - 1;
      let firstDay = new Date(year, month, 1).toLocaleString("it-IT", {
        day: "2-digit",
      });
      let lastDay = new Date(year, month + 1, 0).toLocaleString("it-IT", {
        day: "2-digit",
      });
      let monthDigit = new Date(year, month, 1).toLocaleString("it-IT", {
        month: "2-digit",
      });

      let filter: FileFilterDto = {
        fromDate: year + "-" + monthDigit + "-" + firstDay,
        toDate: year + "-" + monthDigit + "-" + lastDay,
        uploader: [userId!],
        isProtected: safe,
      };

      setFilesCount(await retrieveFilesWithFilterCount(filter, safe));

      retrieveFilesWithFilter(filter, safe, skip, limit)
        .then((files) => {
          if (files)
            setAlbum({
              name:
                new Date(year, month, 1).toLocaleString("it-IT", {
                  month: "long",
                }) +
                " " +
                year,
              files: files,
              albumId: "",
            });
          setLoading(false);
        })
        .catch((err) => console.log(err));
    } else if (folderId.startsWith(DUMMY_ALBUM_IDS.PLACE)) {
      let split = folderId.split("_");
      if (split.length > 1) {
        let city = "";
        if (split.length > 2) city = split[1];
        let country = split[split.length - 1];

        let filter: FileFilterDto = {
          uploader: [userId!],
          isProtected: safe,
          city: city.length > 0 ? city : undefined,
          country: country,
        };

        setFilesCount(await retrieveFilesWithFilterCount(filter, safe));

        retrieveFilesWithFilter(filter, safe, skip, limit)
          .then((files) => {
            if (files)
              setAlbum({
                albumId: "",
                name: city.length > 0 ? city : "TUTTI",
                files: files,
              });

            setLoading(false);
          })
          .catch((err) => console.log(err));
      }
    } else {
      let shared = false;
      if (folderId.includes(DUMMY_ALBUM_IDS.SHARED + "_")) {
        folderId = folderId.substring(folderId.indexOf("_") + 1);
        shared = true;
        setReadOnly(true);
      }

      retrieveAlbum(userId!, folderId, safe, skip, limit)
        .then((a) => {
          if (a) {
            setAlbum({
              name: a.name,
              albumId: shared ? "" : a.albumId,
              creationDate: a.creationDate,
              files: a.files,
              albumSharedToPerson: a.albumSharedToPerson,
            });
            setFilesCount(a.size);
          }

          console.log(a);
          setLoading(false);
        })
        .catch((err) => console.log(err));
    }
  };

  const handleFileSelection = (file: FileDto, selected: boolean) => {
    setMultiSelection((data) => ({
      ...data,
      [file.fileId]: selected ? file : undefined,
    }));
  };

  const deleteAlbum = async () => {
    console.log(album);
    if (!album?.albumId) return setEditingAlbum(null);
    try {
      await deleteAlbumAPI(userId!, album.albumId);
      history.push(ROUTE_FILES);
    } catch (error) {}
  };

  const renameAlbum = async (newName: string) => {
    if (!album?.albumId) return setEditingAlbum(null);
    try {
      await renameAlbumAPI(userId!, album.albumId, newName);
      setAlbum((a) => (a ? { ...a, name: newName } : undefined));
      setEditingAlbum(null);
      dispatch(updateAlbums());
    } catch (error) {}
  };
  const LeftClick_Function = () => {
    if (index !== 0) {
      setindex(index - 1);
    }
  };
  const RightClick_Function = () => {
    if (index !== files.length - 1) {
      setindex(index + 1);
    }
  };
  const shareAlbum = async (shareIds: string[], unshareIds: string[]) => {
    if (!album?.albumId) return setEditingAlbum(null);
    try {
      if (shareIds.length > 0)
        await shareAlbumAPI(userId!, album.albumId, shareIds);
      if (unshareIds.length > 0)
        await unshareAlbumAPI(userId!, album.albumId, unshareIds);
      toast.success("Modifiche effettuate", {
        position: "bottom-right",
        hideProgressBar: true,
      });
      setEditingAlbum(null);
      loadFiles();
    } catch (error) {
      console.log(error);
    }
  };

  const createMultiPostIntent = () => {
    const selectedFiles = Object.keys(multiSelection)
      .filter((id) => multiSelection[id])
      .map((id) => multiSelection[id]) as FileDto[];
    if (selectedFiles.length > 0) setSharedFiles(selectedFiles);
  };

  const createMultiplePost = (
    dto: MultiplePostCreationInterface,
    groupIds: string[]
  ) => {
    addMultiplePostAPI(userId!, dto, groupIds)
      .then(() =>
        toast.success("Post aggiunti", {
          position: "bottom-right",
          hideProgressBar: true,
        })
      )
      .catch((err) => {
        console.log(err);
        toast.error("C'è stato un errore nella creazione dei post", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      })
      .finally(() => {
        console.log("Post creati");
        setSharedFiles(undefined);
        loadFiles();
      });
  };

  const unshareFile = async (groupId: string, fileId: string) => {
    unshareFileAPI(userId!, fileId, groupId)
      .then(() => {
        toast.success("Condivisione annullata", {
          position: "bottom-right",
          hideProgressBar: true,
        });
        loadFiles();
      })
      .catch((error) => {
        console.log(error);
        toast.error(
          "C'è stato un errore nell'annullamento della condivisione",
          {
            position: "bottom-right",
            hideProgressBar: true,
          }
        );
      });
  };

  const multiSelectedCounter = Object.keys(multiSelection).filter(
    (id) => multiSelection[id]
  ).length;
  const files = album?.files || [];
  logCurrent(files);

  const fileFormRef = useRef<FileFormHandler>(null);

  const [modalVisible, setmodalVisible] = useState(false);

  const currentFile = copiedFile ? copiedFile : selectedFile;
  useEffect(() => {
    if (currentFile) {
      setmodalVisible(true);
    } else {
      setmodalVisible(false);
    }
  }, [currentFile]);

  const createNewAlbum = (name: string) => {
    setLoading(true);
    setIsCreatingAlbum(false);
    createAlbumAPI(userId!, name)
      .then(() => dispatch(updateAlbums()))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  };

  return (
    <MainLayout hideFooter contentClassName="folder-content">
      <FileForm
        ref={fileFormRef}
        inSafe={safe}
        modalVisible={modalVisible}
        onClose={(update, copiedFile) => {
          setCopiedFile(copiedFile);
          setSelectedFile(undefined);
          setmodalVisible(false);
          if (update) loadFiles();
        }}
        readOnly={
          copiedFile
            ? false
            : readOnly ||
              (readOnlyShared && selectedFile && isMyFile(selectedFile))
        }
        currentAlbum={copiedFile ? "all" : folderId}
        currentFile={copiedFile ? copiedFile : selectedFile}
        onLinkedAlbum={() => {
          history.push(
            selectedFile?.album?.albumId! + "?page=1&filesPerPage=5"
          );
        }}
      />
      <UploadManager
        type="drag"
        className="folder-dragger"
        disabled={modalVisible}
        showBorder={false}
        onFiles={(f) => {
          setmodalVisible(true);
          fileFormRef.current?.onFiles(f);
        }}
        onSingleFile={(f) => {
          setmodalVisible(true);
          fileFormRef.current?.onSingleFile(f);
        }}
        onFolder={(f) => {
          setmodalVisible(true);
          fileFormRef.current?.onFolder(f);
        }}
        onPreviewReady={(f) => {
          fileFormRef.current?.onPreviewReady(f);
        }}
      >
        <div className="files">
          <FilesHeader
            albumTitle={album ? getLabelOf(album) : ""}
            onOpenFileForm={() => {
              setmodalVisible(true);
            }}
            onCreateAlbum={() => {
              setIsCreatingAlbum(true);
            }}
            safe={safe}
            readOnly={
              copiedFile
                ? false
                : readOnly ||
                  (readOnlyShared && selectedFile && isMyFile(selectedFile))
            }
            onFilter={(filter) => {}}
            onClose={(update, copiedFile) => {
              setCopiedFile(copiedFile);
              setSelectedFile(undefined);
              if (update) loadFiles();
            }}
            currentAlbum={copiedFile ? "all" : folderId}
            currentFile={copiedFile ? copiedFile : selectedFile}
            onLinkedAlbum={() => {
              history.push(
                selectedFile?.album?.albumId! + "?page=1&filesPerPage=5"
              );
            }}
          />

          <div>
            <Button
              onClick={() => {
                setgridMode(true);
              }}
              className={gridMode ? "toggled" : ""}
            >
              <AppstoreOutlined style={{ fontSize: 20 }} />
            </Button>
            <Button
              onClick={() => {
                setgridMode(false);
              }}
              className={!gridMode ? "toggled" : ""}
            >
              <UnorderedListOutlined style={{ fontSize: 20 }} />
            </Button>
          </div>

          <Modal
            visible={!!fileToDelete}
            footer={[
              <Button
                type="primary"
                onClick={() => {
                  if (fileToDelete)
                    deleteFiles([fileToDelete?.fileId])
                      .then((res) => {
                        setFileToDelete(undefined);
                        loadFiles();
                      })
                      .finally(() => {
                        dispatch(retrieveStorage());
                      });
                  toast.success("Il file è stato eliminato", {
                    position: "bottom-right",
                    hideProgressBar: true,
                  });
                }}
              >
                Elimina
              </Button>,

              <Button
                onClick={() => {
                  setFileToDelete(undefined);
                }}
              >
                Annulla
              </Button>,
            ]}
            title="Elimina documento"
            centered
            destroyOnClose
            maskStyle={{
              backdropFilter: "blur(3px)",
              backgroundColor: "unset",
            }}
            bodyStyle={{ padding: 40 }}
            onCancel={() => {
              setFileToDelete(undefined);
            }}
            width={"fit-content"}
          >
            <div>{"Sicuro di voler eliminare il documento selezionato?"}</div>
          </Modal>

          <Drawer
            className={"drawer open" + (safe ? " safe" : "")}
            label={album ? getLabelOf(album) : ""}
            showActions={
              !!album?.albumId &&
              !album.albumId.startsWith(DUMMY_ALBUM_IDS.TAG) &&
              !(album.name === DUMMY_ALBUM_IDS.TEMP)
            }
            openDeleteModal={() => setEditingAlbum("delete")}
            openRenameModal={() => setEditingAlbum("rename")}
            openShareModal={() => setEditingAlbum("share")}
            selectedElements={multiSelectedCounter}
            createNewPostIntent={createMultiPostIntent}
            removeMultiSelection={() => setMultiSelection({})}
          >
            <div
              style={{ display: "flex", justifyContent: "center", padding: 20 }}
            >
              <Pagination
                showTotal={(total, range) =>
                  `${range[0]}-${range[1]} di ${total} files`
                }
                showSizeChanger
                onChange={(page, size) => {
                  //setpage(page);
                  //if (size) setfilesPerPage(size);
                  params.set("page", page.toString());
                  params.set(
                    "filesPerPage",
                    size ? size.toString() : filesPerPage.toString()
                  );
                  history.replace({
                    pathname: history.location.pathname,
                    search: decodeURIComponent(params.toString()),
                  });
                }}
                pageSize={filesPerPage}
                current={page}
                total={filesCount}
                pageSizeOptions={["5", "10", "20", "50"]}
              />
            </div>
            {!gridMode && (
              <div
                style={{
                  marginLeft: 150,
                  fontSize: "14px",
                  display: "flex",
                  alignItems: "center",
                  gap: 185,
                  padding: 10,
                  fontWeight: 300,
                }}
              >
                {" "}
                <div>Descrizione</div>
                <div>Tags</div>
                <div>Data e Luogo</div>
              </div>
            )}
            <div className={gridMode ? "files-grid-layout" : "files-list"}>
              {files?.map((file, idx) => (
                <div
                  key={idx}
                  style={
                    idx % 2 === 0 && !gridMode
                      ? { backgroundColor: "#f8f8f8" }
                      : undefined
                  }
                >
                  <FileItem
                    gridMode={gridMode}
                    key={file.fileId}
                    file={file}
                    openFileImage={() => {
                      setShowFileViewerModal(true);
                      setindex(idx);
                    }}
                    readOnly={readOnly || isMyFile(file)}
                    selectFile={() => setSelectedFile(file)}
                    downloadFile={() => downloadFile(file)}
                    openDeleteModal={() => setFileToDelete(file)}
                    openShareModal={() => setSharedFiles([file])}
                    multiSelected={!!multiSelection[file.fileId]}
                    onMultiSelect={(v) => handleFileSelection(file, v)}
                  />
                  {!gridMode && <div style={{ borderBottom: "1px solid" }} />}
                </div>
              ))}
            </div>
          </Drawer>
          {showFileViewerModal && (
            <Modal
              visible={showFileViewerModal}
              cancelText="Chiudi"
              onCancel={() => {
                setShowFileViewerModal(false);
              }}
              okButtonProps={{ hidden: true }}
              onOk={undefined}
            >
              <div className="folderImage">
                <LeftOutlined onClick={LeftClick_Function} />
                <SafeImage
                  onClick={() => {
                    setselectImage(true);
                  }}
                  style={{
                    borderRadius: "5%",
                    height: 250,
                    width: 250,
                    objectFit: "cover",
                  }}
                  preview
                  file={files[index]}
                  src={
                    files[index].fileType == "image"
                      ? files[index].uri
                      : files[index].thumbnail_uri
                  }
                  fallback={
                    <div
                      style={{ width: "100%", height: 300 }}
                      className="centered"
                    >
                      Errore
                    </div>
                  }
                />
                <RightOutlined onClick={RightClick_Function} />
              </div>
              <div>
                <FileActionButtons
                  file={files[index]}
                  selectFile={() => {
                    setShowFileViewerModal(false);
                    return setSelectedFile(files[index]);
                  }}
                  downloadFile={() => {
                    return downloadFile(files[index]);
                  }}
                  openDeleteModal={() => {
                    setShowFileViewerModal(false);

                    return setFileToDelete(files[index]);
                  }}
                  openShareModal={() => {
                    setShowFileViewerModal(false);

                    return setSharedFiles([files[index]]);
                  }}
                  readOnly={readOnly}
                ></FileActionButtons>
              </div>
            </Modal>
          )}
          <ConfirmationModal
            visible={editingAlbum === "delete"}
            message="Vuoi eliminare questo album?"
            onCancel={() => setEditingAlbum(null)}
            onConfirm={deleteAlbum}
          />
          <RenameModal
            visible={editingAlbum === "rename"}
            onConfirm={renameAlbum}
            onCancel={() => setEditingAlbum(null)}
            initialName={album?.name}
          />
          <CreatePostFromFilesModal
            files={sharedFiles}
            userId={userId!}
            close={() => setSharedFiles(undefined)}
            createMultiplePost={createMultiplePost}
            onUnshareFile={unshareFile}
          />
          <ShareAlbumModal
            visible={editingAlbum === "share"}
            userId={userId!}
            album={album}
            onSubmit={shareAlbum}
            onClose={() => setEditingAlbum(null)}
          />
        </div>
      </UploadManager>
      <AlbumModal
        open={isCreatingAlbum}
        onClose={() => setIsCreatingAlbum(false)}
        onSubmit={createNewAlbum}
      />
    </MainLayout>
  );
};

interface RenameModalProps {
  initialName?: string;
  visible?: boolean;
  onCancel?: () => void;
  onConfirm?: (newName: string) => void;
}

const RenameModal: React.FunctionComponent<RenameModalProps> = ({
  initialName,
  visible,
  onCancel,
  onConfirm,
}) => {
  const [newName, setNewName] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    if (initialName) setNewName(initialName);
  }, [visible]);

  const onConfirmOverride = () => {
    if (!newName) return setError("Inserisci il nuovo nome dell'album");
    onConfirm && onConfirm(newName);
  };

  return (
    <ConfirmationModal
      visible={visible}
      confirmLabel="Rinomina"
      onCancel={onCancel}
      onConfirm={onConfirmOverride}
    >
      <Form.Item label="Nome Album" name="name">
        <Input
          value={newName}
          onChange={(e) => setNewName(e.target.value)}
          placeholder="Nome"
        />
        {error && (
          <span style={{ marginTop: 5, color: "#f5222d" }}>{error}</span>
        )}
      </Form.Item>
    </ConfirmationModal>
  );
};

export default Folder;
