import {
  CloseCircleOutlined,
  CloseOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { Tooltip } from "antd";

export interface DrawerProps extends React.HTMLAttributes<HTMLDivElement> {
  label?: string;
  openDeleteModal?: () => void;
  openRenameModal?: () => void;
  openShareModal?: () => void;
  showActions?: boolean;
  selectedElements?: number;
  createNewPostIntent?: () => void;
  removeMultiSelection?: () => void;
}

export const Drawer: React.FunctionComponent<DrawerProps> = ({
  label,
  openDeleteModal,
  openRenameModal,
  openShareModal,
  showActions,
  selectedElements,
  createNewPostIntent,
  removeMultiSelection,
  ...rest
}) => {
  return (
    <div className="drawer" {...rest}>
      <div className="back-container">
        <div className="back"></div>
      </div>
      <div className="body">
        <div className="folder-header">

          <div className="line"></div>
          {showActions ? (
            <div className="drawer-content-header">
              <div
                className="side"
                style={{ padding: 10, justifyContent: "flex-start" }}
              ></div>
              <div className="center">
                
              </div>
              <div className="side" style={{ padding: 10, gap: 20 }}>
                <div style={{ cursor: "pointer" }} onClick={openShareModal}>
                  Condividi
                </div>
                <div style={{ cursor: "pointer" }} onClick={openRenameModal}>
                  Rinomina
                </div>
                <div style={{ cursor: "pointer" }} onClick={openDeleteModal}>
                  Elimina
                </div>
              </div>
            </div>
          ) : (
            <>
             
            </>
          )}
          {!!selectedElements && (
            <div className="drawer-action-container">
              <div className="left-side">
                <button className="no-style-btn" onClick={removeMultiSelection}>
                  <CloseCircleOutlined color="black" />
                </button>
                <span>File Selezionati: {selectedElements}</span>
              </div>
              {createNewPostIntent && (
                <button
                  className="text-btn action-button"
                  onClick={createNewPostIntent}
                >
                  <ShareAltOutlined />
                  Condividi
                </button>
              )}
            </div>
          )}
          <div className="line"></div>
       
        
 
        </div>

        {rest.children}
      </div>
    </div>
  );
};
