import { ref } from "firebase/database";
import { MessagePayload, onMessage } from "firebase/messaging";
import { FunctionComponent, useEffect } from "react";
import { FirebaseConfig } from "./FirebaseConfig";
import {
  retrieveNotifications,
  selectNotifications,
  selectProfile,
  setLoadNewPost,
} from "./store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "./store/hooks";
import {
  NotificationInterface,
  NotificationTypes,
} from "./types/NotificationTypes";

interface FirebaseManagerProps {}

const FirebaseManager: FunctionComponent<FirebaseManagerProps> = () => {
  let dispatch = useAppDispatch();
  let userId = useAppSelector(selectProfile)?.personId;
  let token = useAppSelector(selectNotifications).token;

  if (!FirebaseConfig.analytics) FirebaseConfig.initAnalytics();

  if (!FirebaseConfig.messaging && userId) FirebaseConfig.initMessaging(userId);

  if (navigator.serviceWorker) {
    const onAnyMessage = (payload: MessagePayload) => {
      if (userId) dispatch(retrieveNotifications(userId));
    };

    navigator.serviceWorker.register("/firebase-messaging-sw.js");
    const swListener = new BroadcastChannel("sw-messages");
    swListener.onmessage = function (e) {
      console.log("Background message captured");
      onAnyMessage(e.data);
    };

    if (FirebaseConfig.messaging) {
      onMessage(FirebaseConfig.messaging, (payload) => {
        console.log("Foreground Message received. ", payload);
        onAnyMessage(payload);
      });
    }
  }

  return null;
};

export default FirebaseManager;
