import { FileDto } from "../components/files/Types";
import { DUMMY_ALBUM_IDS } from "../pages/Folder";
import { PersonInterface } from "./PersonTypes";

export interface AlbumSharedToPerson {
  id: string,
  sharingDate: string,
  uploader: PersonInterface;

}

export interface AlbumInterface {
  albumId: string;
  name: string;
  creationDate?: string;
  files?: FileDto[];
  size?: number;
  creator?: string;

  albumSharedToPerson?: AlbumSharedToPerson[];
  cities?: string[];
}

export const getLabelOf = (album: AlbumInterface) => {
  switch (album.name) {
    case DUMMY_ALBUM_IDS.TEMP:
      return "SCRIVANIA";
    default:
      return album.name;
  }
};
