import {
  AudioOutlined,
  CameraOutlined,
  FileExcelOutlined,
  FileImageOutlined,
  FileOutlined,
  FilePdfFilled,
  FilePdfOutlined,
  FilePptOutlined,
  FileWordOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import EXIF from "exif-js";

export function formatBytes(bytes: number, decimals = 2) {
  if (bytes === 0) return "0 Bytes";

  const k = 1024;
  const dm = decimals < 0 ? 0 : decimals;
  const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

  const i = Math.floor(Math.log(bytes) / Math.log(k));

  return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
}

export function getBase64(img: RcFile, callback: (res: string | null) => void) {
  const reader = new FileReader();
  reader.addEventListener("load", () => callback(reader.result as string));
  reader.readAsDataURL(img);
}

export function splitNameExtension(fullname: string | undefined) {
  let res = ["", ""];
  var re = /(?:\.([^.]+))?$/;
  if (fullname) {
    let ext = re.exec(fullname) as string[];
    return [fullname.replace(ext[0], ""), ext[1]];
  }
  return res;
}

export const readExifData = async (
  file: File
): Promise<ParsedExifData | undefined> => {
  let exifTags = EXIF.readFromBinaryFile(await file.arrayBuffer()) as
    | ExifTags
    | undefined;

  let parsedExifData: ParsedExifData | undefined;
  if (exifTags) {
    parsedExifData = parseExifData(exifTags);
  }
  return parsedExifData;
};

export const parseExifGPS = (
  values?: [Number, Number, Number],
  signal?: string
) => {
  if (!values) return;
  let l = values.map((l) => l.numerator / l.denominator);
  let value = (l[0] + l[1] / 60 + l[2] / 3600).toFixed(6);
  if (signal === "S" || signal === "W") return -value;
  return value;
};

export const parseExifData = (
  exifTags?: ExifTags
): ParsedExifData | undefined => {
  if (!exifTags) return;
  let latitude = parseExifGPS(
    exifTags.GPSLatitude,
    exifTags.GPSLatitudeRef
  )?.toString();
  let longitude = parseExifGPS(
    exifTags.GPSLongitude,
    exifTags.GPSLongitudeRef
  )?.toString();

  let timestamp =
    exifTags.DateTimeOriginal ??
    exifTags.DateTimeDigitized ??
    exifTags.DateTime;

  if (timestamp != null) {
    timestamp = timestamp.replace(":", "-");
    timestamp = timestamp.replace(":", "-");
    timestamp = timestamp.replace(" ", "T");
  }
  return {
    latitude,
    longitude,
    timestamp,
  };
};
export interface ParsedExifData {
  timestamp?: string;
  latitude?: string;
  longitude?: string;
}

export interface ExifTags {
  GPSLatitude?: [Number, Number, Number];
  GPSLongitude?: [Number, Number, Number];
  GPSLatitudeRef?: "N" | "S";
  GPSLongitudeRef?: "W" | "E";
  DateTime?: string;
  DateTimeDigitized?: string;
  DateTimeOriginal?: string;
}

interface Number {
  numerator: number;
  denominator: number;
}
export function chooseIconByFileType(
  fileType: string,
  fontSize: number,
  color?: string
) {
  let uriIcon;
  switch (fileType) {
    case "image":
      uriIcon = (
        <FileImageOutlined style={{ fontSize: fontSize, color: color }} />
      );
      break;
    case "audio":
      uriIcon = (
        <>
          <div style={{ position: "relative" }}>
            <FileOutlined style={{ fontSize: fontSize, color: color }} />
            <div
              style={{ position: "absolute", marginTop: -25, marginLeft: 10 }}
            >
              <AudioOutlined style={{ fontSize: fontSize / 2, color: color }} />
            </div>
          </div>
        </>
      );
      break;
    case "video":
      uriIcon = (
        <>
          <div style={{ position: "relative" }}>
            <FileOutlined style={{ fontSize: fontSize, color: color }} />
            <div
              style={{ position: "absolute", marginTop: -25, marginLeft: 10 }}
            >
              <VideoCameraOutlined
                style={{ fontSize: fontSize / 2, color: color }}
              />
            </div>
          </div>
        </>
      );
      break;
    case "text":
      uriIcon = (
        <FileWordOutlined style={{ fontSize: fontSize, color: color }} />
      );
      break;
    case "pdf":
      uriIcon = (
        <FilePdfOutlined style={{ fontSize: fontSize, color: color }} />
      );
      break;
    case "spreadsheet":
      uriIcon = (
        <FileExcelOutlined style={{ fontSize: fontSize, color: color }} />
      );
      break;
    case "presentation":
      uriIcon = (
        <FilePptOutlined style={{ fontSize: fontSize, color: color }} />
      );
      break;

    default:
      uriIcon = <FileOutlined style={{ fontSize: fontSize, color: color }} />;
      break;
  }

  return uriIcon;
}

export function chooseIconByFileExt(fileExt: string, fontSize: number) {
  let uriIcon;
  switch (fileExt) {
    case "png":
    case "jpg":
    case "jpeg":
    case "gif":
      uriIcon = <FileImageOutlined style={{ fontSize: fontSize }} />;
      break;
    case "wav":
    case "mp3":
    case "aac":
    case "wma":
    case "m4a":
    case "flac":
      uriIcon = (
        <>
          <div style={{ position: "relative" }}>
            <FileOutlined style={{ fontSize: fontSize }} />
            <div
              style={{ position: "absolute", marginTop: -38, marginLeft: 14 }}
            >
              <AudioOutlined style={{ fontSize: fontSize / 2 }} />
            </div>
          </div>
        </>
      );
      break;
    case "avi":
    case "mkv":
    case "mp4":
    case "mpg":
    case "mpeg":
    case "wmv":
      uriIcon = (
        <>
          <div style={{ position: "relative" }}>
            <FileOutlined style={{ fontSize: fontSize }} />
            <div
              style={{ position: "absolute", marginTop: -38, marginLeft: 15 }}
            >
              <VideoCameraOutlined style={{ fontSize: fontSize / 2 }} />
            </div>
          </div>
        </>
      );
      break;
    case "doc":
    case "docx":
    case "odt":
    case "txt":
      uriIcon = <FileWordOutlined style={{ fontSize: fontSize }} />;
      break;
    case "pdf":
      uriIcon = <FilePdfOutlined style={{ fontSize: fontSize }} />;
      break;
    case "ods":
    case "xls":
    case "xlsm":
    case "xlsx":
      uriIcon = <FileExcelOutlined style={{ fontSize: fontSize }} />;
      break;
    case "ppt":
    case "pptx":
      uriIcon = <FilePptOutlined style={{ fontSize: fontSize }} />;
      break;

    default:
      uriIcon = <FileOutlined style={{ fontSize: fontSize }} />;
      break;
  }

  return uriIcon;
}

export function formatChatFileType(fileType: string) {
  let t = "Allegato"
  switch(fileType) {
    case "image" :
      t="Foto";
      break;
    
    case "audio":
      t="Audio";
      break;

    case "video":
      t="Video";
      break;
    
    case "text":
    case "pdf":
    case "spreadsheet":
    case "presentation":
      t="Documento";
      break;
    
    default:
      t="Allegato";
      break;
  }

  return t;
}
