import localizer from "i18n-iso-countries";
import i18n from "i18next";

import IT from "i18n-iso-countries/langs/it.json";
import EN from "i18n-iso-countries/langs/en.json";

localizer.registerLocale(IT);
localizer.registerLocale(EN);

let languages = [];
languages.push(...navigator.languages);
languages.push(navigator.language);

let firstLanguageCandidate = languages.find((e) => e.includes("-"));
export const initialCountry =
  firstLanguageCandidate?.substring(firstLanguageCandidate.indexOf("-") + 1) ||
  "";

var cities: { name: string; country: string }[];

export const getCities = async () => {
  let cities = await import("../assets/cities.json");
  return cities.default as { name: string; country: string }[];
};

export const getCitiesByCountry = async (countryCode: string) => {
  if (!cities) {
    console.log("loading cities");
    let imp = await import("../assets/cities.json");
    cities = imp.default as { name: string; country: string }[];
  }
  return cities.filter((city) => city.country === countryCode);
};

export const getLocalizedCountries = () => {
  let countries = localizer.getNames(i18n.language.substr(0, 2), {
    select: "official",
  });
  return countries;
};

export const getLocalizedCountry = (countryCode: string) => {
  return localizer.getName(countryCode, i18n.language.substr(0, 2), {
    select: "official",
  });
};
