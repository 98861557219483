import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { COLORS } from "../Constants";
import Joyride, {
  ACTIONS,
  LIFECYCLE,
  STATUS,
  Step,
  StoreHelpers,
} from "react-joyride";
import { Tour } from "../tour/types";
import { sendTourCompleted } from "../service/tourAPI";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { selectProfile } from "../store/auth/authSlice";
import { selectTourStatus, tourCompleted } from "../store/tour/tourSlice";

interface TourManagerProps {
  tour: Tour;
  allowSkip?: boolean;
  autorun?: boolean;
  sendComplete?: boolean;
  onComplete?: (auto: boolean) => void;
  onProgress?: (index: number) => void;
  spotlightPadding?: number;
  spotlightRadius?: number;
  disableScrolling?: boolean;
}

export type TourHandler = {
  start: () => void;
};

const TourManager: React.ForwardRefRenderFunction<
  TourHandler,
  TourManagerProps
> = (
  {
    tour,
    allowSkip = false,
    autorun = false,
    sendComplete = true,
    spotlightPadding = 0,
    spotlightRadius = 30,
    onComplete = () => {},
    disableScrolling,
    onProgress = (i) => {},
  },
  ref
) => {
  const [tourHelpers, setTourHelpers] = useState<StoreHelpers>();

  const [index, setindex] = useState(0);
  const [run, setrun] = useState<boolean>(false);
  const [startedAuto, setstartedAuto] = useState(false);

  const userId = useAppSelector(selectProfile)?.personId!;
  const tourStatus = useAppSelector(selectTourStatus);

  const dispatch = useAppDispatch();

  useEffect(() => {
    maybeAutoStart();
  }, [tourStatus]);

  const maybeAutoStart = async () => {
    if (!autorun || !tour.key) return;

    console.log(tourStatus);
    let status =
      tourStatus && tour.key in tourStatus ? tourStatus[tour.key] : 0;
    if (status < tour.version) {
      setstartedAuto(true);

      start();
    }
  };

  useImperativeHandle(ref, () => ({
    start,
  }));

  const start = () => {
    setrun(true);
    tourHelpers?.open();
  };

  return (
    <Joyride
      disableScrolling={disableScrolling}
      steps={tour.steps}
      locale={{
        open: "Info",
        close: "Chiudi",
        back: "Indietro",
        next: index !== 0 ? "Avanti" : "Inizia",
        skip: "Salta",
        last: "Fine",
      }}
      callback={(data) => {
        console.log(data);
        if (data.lifecycle === LIFECYCLE.READY) {
          setindex(data.index);
          onProgress(data.index);
        }

        if (
          data.status === STATUS.FINISHED ||
          data.status === STATUS.SKIPPED ||
          (data.action === ACTIONS.CLOSE &&
            data.lifecycle === LIFECYCLE.COMPLETE)
        ) {
          if (sendComplete) dispatch(tourCompleted({ userId, tour }));

          onComplete(startedAuto);
          setrun(false);
        }
      }}
      continuous={true}
      getHelpers={(helpers) => {
        setTourHelpers(helpers);
      }}
      run={run}
      scrollToFirstStep={true}
      showProgress={index !== 0 && index !== tour.steps.length - 1}
      showSkipButton={allowSkip}
      spotlightPadding={spotlightPadding}
      styles={{
        spotlight: {
          borderRadius: spotlightRadius,
        },
        tooltipContent: {
          paddingTop: 40,
        },
        buttonNext: {
          paddingLeft: 15,
          paddingRight: 15,
          outline: "none",
        },

        buttonBack: {
          border: `1px solid ${COLORS.orange}`,
          borderRadius: 5,
          position: !allowSkip ? "absolute" : undefined,
          outline: "none",
          left: 15,
        },
        options: {
          zIndex: 20000,
          arrowColor: "#fff",
          backgroundColor: "#fff",
          beaconSize: 36,

          overlayColor: "rgba(0, 0, 0, 0.5)",
          primaryColor: COLORS.orange,
          spotlightShadow: "0 0 15px rgba(0, 0, 0, 0.5)",
          textColor: COLORS.darkGreen,
        },
      }}
    />
  );
};

export default forwardRef(TourManager);
