import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import "./Localizer";
import App from "./App";
import { store, persistor } from "./store";
import { Provider } from "react-redux";
import * as serviceWorker from "./serviceWorker";
import { PersistGate } from "redux-persist/integration/react";
import { ConfigProvider } from "antd";
import it from "antd/lib/locale/it_IT";
import moment from "moment";
import "moment/locale/it";
import { isMobile } from "react-device-detect";
import {
  MOBILE_APP_URL,
  PRODUCTION_MODE,
  ROUTE_HELP,
  ROUTE_REGISTRATION,
  ROUTE_SHARE,
} from "./Constants";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import * as Sentry from "@sentry/react";

moment.locale("it");

const params = new URLSearchParams(window.location.search);

const WEB_ONLY_PUBLIC_PAGES = [ROUTE_HELP, ROUTE_SHARE];

console.log(window.location);

if (PRODUCTION_MODE)
  Sentry.init({
    dsn: "https://e3ba9bee89313de7698b18ba5cf9f6bb@o4506178847506432.ingest.sentry.io/4506178861531136",
  });

if (
  isMobile &&
  params.get("mobile") !== "true" &&
  window.location.pathname != MOBILE_APP_URL &&
  !WEB_ONLY_PUBLIC_PAGES.includes(window.location.pathname)
) {
  if (
    window.location.pathname.includes(ROUTE_REGISTRATION) &&
    window.location.search.includes("code")
  ) {
    window.location.pathname =
      MOBILE_APP_URL + "/#/register" + window.location.search;
  } else {
    window.location.pathname = MOBILE_APP_URL;
  }
} else {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <GoogleReCaptchaProvider reCaptchaKey="6LdnTCcfAAAAAIBWFwc7KPpchPcMGs7lUn2SEUCt">
          <PersistGate persistor={persistor}>
            <ConfigProvider locale={it}>
              <App />
            </ConfigProvider>
          </PersistGate>
        </GoogleReCaptchaProvider>
      </Provider>
    </React.StrictMode>,
    document.getElementById("root")
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
