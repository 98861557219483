import axios from "axios";
import { API_URL } from "../Constants";
import { FriendRequestNotificationInterface } from "../types/NotificationTypes";

const RETRIEVE_NOTIFICATIONS_URL = API_URL + "/graph/notification/retrieveAll";
const READ_NOTIFICATION_URL = API_URL + "/graph/notification/readNotification";

const ACCEPT_NOTIFICATION_URL =
  API_URL + "/graph/notification/acceptNotification";
const REJECT_NOTIFICATION_URL =
  API_URL + "/graph/notification/rejectNotification";

const SEND_FCM_TOKEN_URL =
  API_URL + "/graph/notification/addFirebaseRegistrationToken";

export const retrieveNotificationsAPI = async (
  userId: string,
  pending: boolean = true
): Promise<{
  data: FriendRequestNotificationInterface[];
  pendingNotifications: number;
}> => {
  const res = (
    await axios.get(RETRIEVE_NOTIFICATIONS_URL, { params: { userId, pending } })
  ).data;
  return {
    data: res.notifications,
    pendingNotifications: Number(res.pendingNotifications),
  };
};

export const readNotificationAPI = async (
  userId: string,
  notificationIDs: (number | string)[]
): Promise<any> => {
  const res = await axios.put(READ_NOTIFICATION_URL, notificationIDs, {
    params: { userId },
  });
  return res;
};

export const acceptNotificationAPI = async (
  userId: string,
  notificationId: string
): Promise<any> => {
  const res = await axios.put(ACCEPT_NOTIFICATION_URL, null, {
    params: { userId, notificationId },
  });
  return res;
};

export const rejectNotificationAPI = async (
  userId: string,
  notificationId: string
): Promise<any> => {
  const res = await axios.put(REJECT_NOTIFICATION_URL, null, {
    params: { userId, notificationId },
  });
  return res;
};

export const sendFCMToken = async (personId: string, token: string) => {
  const res = await axios.post(SEND_FCM_TOKEN_URL, token, {
    params: { personId },
    headers: { "Content-Type": "text/plain" },
  });
  return res;
};
