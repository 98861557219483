import DocViewer, {
  DocRenderer,
  DocViewerRenderers,
} from "@cyntler/react-doc-viewer";
import { Image, message, Modal, Tooltip } from "antd";
import React from "react";
import { ImgHTMLAttributes, useState } from "react";
import ReactPlayer from "react-player";
import FileViewer from "./files/FileViewer";
import { FileDto } from "./files/Types";

export enum FallBackType {
  NONE,
  LOGO,
  GONE,
  WHITE,
  INITIALS,
}
export interface SafeImageProps extends ImgHTMLAttributes<any> {
  fallback: FallBackType | JSX.Element;
  preview?: boolean;
  backgroundColor?: string;
  initialsWeight?: number;
  fullname?: {
    name: string;
    surname: string;
  };
  file?: FileDto;
  isfileCard?: boolean;
}

export const SafeImage: React.FunctionComponent<SafeImageProps> = ({
  fallback = FallBackType.NONE,
  initialsWeight = 500,
  backgroundColor = "white",
  preview = false,
  isfileCard = false,
  ...props
}: SafeImageProps) => {
  const [error, setError] = useState<boolean>(false);
  const [adaptHeight, setAdaptHeight] = useState<boolean>(false);
  const [loadedImg, setloadedImg] = useState<HTMLImageElement>();
  const [showFileViewer, setShowFileViewer] = useState(false);

  if (!error && (fallback === FallBackType.NONE || props.src))
    if (preview)
      return (
        <>
          <Image
            id={props.file?.uri}
            preview={
              props.file
                ? props.file?.fileType == "image"
                  ? { src: props.file.uri }
                  : false
                : preview
            }
            style={{
              ...props.style,
              background: backgroundColor,
              objectFit: "cover",
            }}
            {...props}
            src={props.src}
            onError={() => setError(true)}
            onClick={(e) => {
              if (props.file?.fileType != "image") {setShowFileViewer(true);
              }
              if (props.onClick){
                props.onClick(e);
              }
            }}
            onMouseOver={(e) => (e.currentTarget.style.cursor = "pointer")}
          />

          {props.file && (
            <FileViewer
              visible={showFileViewer}
              file={props.file}
              onCancel={() => setShowFileViewer(false)}
              viewerStyle={{ width: 800, height: 800 }}
              zIndex={1003}
            />
          )}
        </>
      );
    else {
      return (
        <div
          {...props}
          style={{
            ...props.style,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            overflow: "hidden",
            width:
              props.style?.width ??
              (loadedImg && props.style?.height
                ? `calc(${props.style?.height}px * ${
                    loadedImg!.height / loadedImg!.width
                  })`
                : undefined),
            height:
              props.style?.height ??
              (loadedImg && props.style?.width
                ? `calc(${props.style?.width}px * ${
                    loadedImg!.height / loadedImg!.width
                  })`
                : undefined),
          }}
        >
          <img
            src={props.src}
            style={{
              background: backgroundColor,
              width: isfileCard ? 100 : adaptHeight ? "100%" : "unset",
              height: isfileCard ? 160 : !adaptHeight ? "100%" : "unset",
            }}
            onLoad={(e) => {
              setloadedImg(e.currentTarget);
              if (
                e.currentTarget.naturalHeight > e.currentTarget.naturalWidth
              ) {
                setAdaptHeight(true);
              }
            }}
            onError={() => {
              setError(true);
            }}
          />
        </div>
      );
    }

  switch (fallback) {
    case FallBackType.INITIALS:
      return (
        <div
          {...props}
          style={{
            background: backgroundColor,
            ...props.style,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {props.fullname && (
            <svg
              width="100%"
              height="100%"
              viewBox="0 0 100 100"
              preserveAspectRatio="xMinYMid meet"
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
            >
              <text
                x="50%"
                y="55%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize={40}
                fontWeight={initialsWeight}
                fill="darkslategray"
                //fill="black"
              >
                {`${(props.fullname.name[0] ?? "").toUpperCase()}${(
                  props.fullname.surname[0] ?? ""
                ).toUpperCase()}`}
              </text>
            </svg>
          )}
        </div>
      );
    case FallBackType.WHITE:
      return <div {...props} style={{ background: "white", ...props.style }} />;
    case FallBackType.LOGO:
      return (
        <Image
          {...props}
          preview={preview}
          src="/assets/images/logo.png"
          onError={() => setError(true)}
          alt=""
        />
      );
    case FallBackType.GONE:
      return <div {...props} />;
    case FallBackType.NONE:
      return null;
  }

  return React.cloneElement(fallback, { ...props });
};
