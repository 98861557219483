import {
  FileOutlined,
  CameraOutlined,
  FolderOpenOutlined,
  FolderAddOutlined,
  UploadOutlined,
  FileAddOutlined,
} from "@ant-design/icons";
import LoadingOverlay from "react-loading-overlay-ts";

import {
  Modal,
  Form,
  Input,
  Select,
  Button,
  message,
  Switch,
  Spin,
  Tooltip,
  SelectProps,
} from "antd";
import Upload, { RcFile } from "antd/lib/upload";
import Dragger from "antd/lib/upload/Dragger";
import {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { MAX_UPLOAD_SIZE, COLORS } from "../../Constants";
import {
  addMultiplePostAPI,
  MultiplePostCreationInterface,
} from "../../service/feedAPI";
import {
  addFilesToAlbum,
  moveFileFromToAlbum,
  removeFilesFromAlbum,
  copySharedFiles,
  updateFileDto,
  uploadFile,
  unshareFileAPI,
} from "../../service/fileAPI";
import {
  retrieveStorage,
  selectAlbums,
  selectProfile,
  selectTags,
  updateAlbums,
  updateTags,
} from "../../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { getLabelOf } from "../../types/AlbumTypes";
import update from "immutability-helper";
import {
  getBase64,
  formatBytes,
  splitNameExtension,
  ExifTags,
  parseExifGPS,
  ParsedExifData,
  parseExifData,
  readExifData,
  chooseIconByFileType,
  chooseIconByFileExt,
} from "../../util/FileUtil";
import CreatePostFromFilesModal from "../CreatePostFromFilesModal";
import { FallBackType, SafeImage } from "../SafeImage";
import { FileDto, TagRelationship } from "./Types";
import { MultipleFileForm } from "./MultipleFileForm";
import { DUMMY_ALBUM_IDS } from "../../pages/Folder";
import {
  getCitiesByCountry,
  getLocalizedCountries,
  getLocalizedCountry,
  initialCountry,
} from "../../util/GeoUtil";
import { useTranslation } from "react-i18next";
import EXIF from "exif-js";
import FileViewer from "./FileViewer";
import { UploadFile, UploadProps } from "antd/lib/upload/interface";
import AlbumModal from "./AlbumModal";
import { createAlbumAPI } from "../../service/albumAPI";
import { RefSelectProps } from "antd/lib/select";
import UploadManager, { isInputDirSupported } from "./UploadManager";
import LoadingOverlayWrapper from "react-loading-overlay-ts";
import { toast } from "react-toastify";

const { Option } = Select;
const { TextArea } = Input;

interface SuperFile {
  file: RcFile;
  src: string;
}
export interface FileFormProps {
  inSafe: boolean;
  modalVisible: boolean;
  readOnly?: boolean;
  currentAlbum?: string;
  currentFile?: FileDto;
  onClose: (update: boolean, copiedFile?: FileDto | undefined) => void;
  onLinkedAlbum: () => void;
}
export type FileFormHandler = {
  onFiles: (f: SuperFile[]) => void;
  onSingleFile: (f: RcFile) => void;
  onFolder: (f: string) => void;
  onPreviewReady: (f: string | null) => void;
};

const FileForm: React.ForwardRefRenderFunction<
  FileFormHandler,
  FileFormProps
> = ({ modalVisible, ...props }, ref) => {
  const [uploadingFile, setUploadingFile] = useState<RcFile>();
  const [uploadingFolder, setuploadingFolder] = useState<string>();
  const [uploadingPreview, setUploadingPreview] = useState<string | null>();
  const [tagSearchValue, setTagSearchValue] = useState<string>();
  const [edited, setEdited] = useState<boolean>();
  const [sharedFiles, setSharedFiles] = useState<FileDto[]>();
  const [isCreatingAlbum, setisCreatingAlbum] = useState(false);
  const [newAlbumName, setNewAlbumName] = useState<string>();

  const [albumValue, setalbumValue] = useState(
    (props.currentAlbum && isNaN(parseInt(props.currentAlbum))
      ? undefined
      : props.currentAlbum) ?? props.currentFile?.album?.albumId
  );
  const [filesList, setFilesList] = useState<SuperFile[]>([]);
  const [newProtected, setNewProtected] = useState<boolean>(
    props.currentFile?.isProtected ?? props.inSafe
  );

  const dispatch = useAppDispatch();
  const [loading, setloading] = useState(false);

  const tags = useAppSelector(selectTags);
  const userAlbums = useAppSelector(selectAlbums);

  const readOnly = props.readOnly && props.currentFile != null;

  const [modalListVisible, setModalListVisible] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const { t } = useTranslation();
  var delayTimer: NodeJS.Timeout;
  const [searchCity, setSearchCity] = useState<string>();
  const [cities, setCities] = useState<{ name: string; country: string }[]>([]);
  const [initialValueCountry, setInitialValueCountry] = useState<
    string | undefined
  >(undefined);
  const [initialValueCity, setInitialValueCity] = useState<string | undefined>(
    undefined
  );
  const [showFileViewer, setShowFileViewer] = useState(false);
  var uri = decodeURI(window.location.pathname);
  uri = uri.substring(uri.lastIndexOf("/") + 1);

  useImperativeHandle(ref, () => ({
    onFiles,
    onFolder,
    onPreviewReady,
    onSingleFile,
  }));

  const onFiles = (f: SuperFile[]) => {
    setFilesList(f);
  };
  const onFolder = (f: string) => {
    setuploadingFolder(f);
  };
  const onPreviewReady = (f: string | null) => {
    setUploadingPreview(f);
  };
  const onSingleFile = (f: RcFile) => {
    setUploadingFile(f);
    form.resetFields(["name", "ext"]);
  };

  useEffect(() => {
    if (uri.includes(DUMMY_ALBUM_IDS.PLACE + "_")) {
      let split = uri.split("_");
      if (split.length > 1) {
        setInitialValueCountry(split[split.length - 1]);
        setSelectedCountry(split[split.length - 1]);
        if (split.length > 2) setInitialValueCity(split[1]);
      }
    }
  }, []);

  useEffect(() => {
    getCitiesByCountry(selectedCountry ?? "").then((values) =>
      setCities(values)
    );
  }, [selectedCountry]);

  useEffect(() => {
    getCitiesByCountry(initialCountry ?? "").then((values) =>
      setCities(values)
    );
  }, []);

  const handleModal = (value: boolean) => {
    setModalListVisible(value);
  };
  const [numFiles, setNumFiles] = useState<number>(0);

  const reset = () => {
    console.log("reset");
    setUploadingFile(undefined);
    setuploadingFolder(undefined);
    setisCreatingAlbum(false);
    setUploadingPreview(undefined);
    form.resetFields();
    setalbumValue(undefined);
    setEdited(false);
  };

  const [form] = Form.useForm();

  const userId = useAppSelector(selectProfile)?.personId;

  const uploadNewFile = async () => {
    let newName =
      (form.getFieldValue("name") ??
        splitNameExtension(uploadingFile?.name)[0]) +
      "." +
      (form.getFieldValue("ext") ?? splitNameExtension(uploadingFile?.name)[1]);
    console.log(newName);

    if (uploadingFile) {
      if (uploadingFile.size >= MAX_UPLOAD_SIZE) {
        return;
      }
      setloading(true);

      let file = new File([uploadingFile], newName, {
        type: uploadingFile.type,
      });

      let exifData = await readExifData(file);

      let tags = form.getFieldValue("tags") as string[] | undefined;

      let album = albumValue;
      let newDesc = form.getFieldValue("description") as string | undefined;
      let prot = newProtected;

      let country = form.getFieldValue("country") as string | undefined;
      let city = form.getFieldValue("city") as string | undefined;

      let place = country ? (city ? country + "/" + city : country) : undefined;

      await uploadFile(
        file,
        userId!,
        tags,
        album,
        newDesc,
        prot,
        place,
        exifData?.timestamp,
        exifData?.latitude,
        exifData?.longitude
      ).then((res) => {
        setloading(false);

        if (res?.status !== 200 && res?.data?.message) {
          if (res.data.message.includes("enum"))
            toast.error("File non supportato", {
              position: "bottom-right",
              hideProgressBar: true,
            });
          else
            toast.error(res.data.message, {
              position: "bottom-right",
              hideProgressBar: true,
            });
        } else {
          reset();
          props.onClose(true);
          dispatch(retrieveStorage());

          if (tags) {
            dispatch(updateTags());
          }
          reset();
          props.onClose(true);
        }
      });
    }
  };

  const createNewAlbum = (name: string) => {
    setloading(true);
    setNewAlbumName(name);
    setisCreatingAlbum(false);
    createAlbumAPI(userId!, name)
      .then(() => dispatch(updateAlbums()))
      .catch((err) => console.log(err))
      .finally(() => setloading(false));
  };

  const copySharedFile = () => {
    setloading(true);
    if (props.currentFile) {
      var copiedFile: FileDto | undefined = undefined;
      copySharedFiles(userId!, [props.currentFile.fileId])
        .then((res) => {
          if (res) {
            toast.success("File salvato!", {
              position: "bottom-right",
              hideProgressBar: true,
            });
            copiedFile = res.get(props.currentFile?.fileId!);
          } else {
            toast.error(
              "Riprova, se il problema persiste puoi scaricare e ricaricare il file",
              {
                position: "bottom-right",
                hideProgressBar: true,
              }
            );
          }
        })
        .finally(() => {
          setloading(false);
          dispatch(retrieveStorage());
          reset();
          props.onClose(false, copiedFile);
        });
    }
  };

  const updateFile = () => {
    if (props.currentFile && userId) {
      let file = props.currentFile;

      let newName = form.getFieldValue("name");
      let newTags = form.getFieldValue("tags") as string[] | undefined;
      let newAlbum = albumValue;
      let newDesc = form.getFieldValue("description") as string | undefined;
      let prot = newProtected ?? false;
      let country = form.getFieldValue("country") as string | undefined;
      let city = form.getFieldValue("city") as string | undefined;
      let place = country ? (city ? country + "/" + city : country) : undefined;

      file.name = newName;
      file.description = newDesc;
      file.isProtected = prot;
      file.place = place;

      if (newTags) {
        let tagRelationships: TagRelationship[] = [];
        newTags.forEach((tag) => {
          tagRelationships.push({ tag: { name: tag } });
        });
        file.tagRelationship = tagRelationships;
      }

      //let tmpAlbum = userAlbums?.find((al) => al.name === DUMMY_ALBUM_IDS.TEMP);
      //if (tmpAlbum)
      //if (newAlbum && newAlbum === tmpAlbum.albumId) newAlbum = undefined; // remove files from "scrivania" on edit

      updateFileDto(userId, file).then(async (success) => {
        let fileAlbumId =
          file.album?.albumId ||
          (props.currentAlbum &&
            Object.values<string>(DUMMY_ALBUM_IDS).includes(
              props.currentAlbum!
            )) ||
          Object.values<string>(DUMMY_ALBUM_IDS).some((a) =>
            props.currentAlbum?.startsWith(a)
          )
            ? undefined
            : props.currentAlbum;

        if (success) {
          dispatch(updateTags());

          if (newAlbum && fileAlbumId && newAlbum !== fileAlbumId) {
            success = await moveFileFromToAlbum(
              userId,
              file.fileId,
              fileAlbumId,
              newAlbum
            );
          } else if (newAlbum && !fileAlbumId) {
            success = await addFilesToAlbum(userId, newAlbum, [file.fileId]);
          } else if (fileAlbumId && !newAlbum) {
            success = await removeFilesFromAlbum(userId, fileAlbumId, [
              file.fileId,
            ]);
          }

          if (success) {
            reset();
            props.onClose(true);
          } else {
            toast.error("Errore", {
              position: "bottom-right",
              hideProgressBar: true,
            });
          }
        } else {
          toast.error("Errore", {
            position: "bottom-right",
            hideProgressBar: true,
          });
        }
      });
    }
  };

  /*const createPost = (dto: PostCreationInterface) => {
    addPostAPI(userId!, dto)
      .then(() => message.success("Post aggiunto"))
      .catch((err) => {
        console.log(err);
        message.error("C'è stato un errore nella creazione del post");
      })
      .finally(() => {
        setSharedFiles(undefined);
        let tmpAlbum = userAlbums?.find(
          (al) => al.name === DUMMY_ALBUM_IDS.TEMP
        );
        if (tmpAlbum && props.currentAlbum === tmpAlbum.albumId) updateFile(); //remove from desk
      });
  }*/

  useEffect(() => {
    if (newAlbumName) {
      setalbumValue(userAlbums?.find((a) => a.name === newAlbumName)?.albumId);
    }
  }, [userAlbums]);

  useEffect(() => {
    console.log("CURRENT ALBUM " + props.currentAlbum);
    setalbumValue(
      (props.currentAlbum &&
      Object.values<string>(DUMMY_ALBUM_IDS).includes(props.currentAlbum!)
        ? undefined
        : props.currentAlbum) ?? props.currentFile?.album?.albumId
    );
  }, [props.currentAlbum, filesList, uploadingFile, props.currentFile]);

  const createMultiplePost = (
    dto: MultiplePostCreationInterface,
    groupIds: string[]
  ) => {
    addMultiplePostAPI(userId!, dto, groupIds)
      .then(() => toast.success("Post aggiunti"))
      .catch((err) => {
        console.log(err);
        toast.error("C'è stato un errore nella creazione dei post", {
          position: "bottom-right",
          hideProgressBar: true,
        });
      })
      .finally(() => {
        console.log("Post creati");
        setSharedFiles(undefined);
        props.onClose(true);
        let tmpAlbum = userAlbums?.find(
          (al) => al.name === DUMMY_ALBUM_IDS.TEMP
        );
        if (tmpAlbum && props.currentAlbum === tmpAlbum.albumId) updateFile(); //remove from desk
      });
  };

  const unshareFile = async (groupId: string, fileId: string) => {
    unshareFileAPI(userId!, fileId, groupId)
      .then(() => toast.success("Condivisione annullata"))
      .catch((error) => {
        console.log(error);
        toast.error(
          "C'è stato un errore nell'annullamento della condivisione",
          {
            position: "bottom-right",
            hideProgressBar: true,
          }
        );
      });
  };

  useEffect(() => {
    form.resetFields();
  }, [modalVisible]);

  useEffect(() => {
    if (filesList.length > 0) setModalListVisible(true);
    form.resetFields();
  }, [filesList]);

  useEffect(() => {
    setNewProtected(props.currentFile?.isProtected ?? props.inSafe);
  }, [props.currentFile, props.inSafe]);

  const showNewAlbumModal = () => {
    setisCreatingAlbum(true);
  };

  const photo_height = "25vh";

  return (
    <>
      <LoadingOverlayWrapper
        styles={{
          wrapper: { display: "contents" },
        }}
        active={loading}
        spinner
        className="loader"
      >
        <Modal
          className="file-form"
          visible={modalVisible && !modalListVisible}
          zIndex={799}
          centered
          footer={[
            readOnly ? (
              <Button type="primary" onClick={() => copySharedFile()}>
                Salva una copia tra i miei documenti
              </Button>
            ) : props.currentFile ? (
              <Button
                type="primary"
                disabled={!edited}
                onClick={() => updateFile()}
              >
                Salva
              </Button>
            ) : (
              <Button
                type="primary"
                disabled={
                  !uploadingFile || uploadingFile.size >= MAX_UPLOAD_SIZE
                }
                onClick={() => {
                  uploadNewFile();
                }}
              >
                Carica
              </Button>
            ),
            props.currentFile && !readOnly && (
              <Button
                type="primary"
                onClick={() => setSharedFiles([props.currentFile!])}
              >
                Condividi
              </Button>
            ),
            <Button
              onClick={() => {
                reset();
                props.onClose(false);
              }}
            >
              {readOnly ? "Chiudi" : "Annulla"}
            </Button>,
          ]}
          destroyOnClose
          maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
          onCancel={() => {
            setNumFiles(0);
            reset();
            props.onClose(false);
          }}
          width={"fit-content"}
          style={{
            minWidth: 400,
            minHeight: 400,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: 20,
              width: "100%",
              padding: 20,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignContent: "center",
                gap: 140,
              }}
            >
              <div>
                {readOnly
                  ? "VISUALIZZA"
                  : props.currentFile
                  ? "MODIFICA"
                  : "AGGIUNGI"}
              </div>

              {props.currentFile && (
                <Button
                  type="primary"
                  style={{
                    width: "fit-content",
                    height: 25,
                    display: "flex",
                    alignItems: "center",
                  }}
                  onClick={() => {
                    if (props.currentFile?.fileType == "image")
                      document.getElementById(props.currentFile.uri)?.click();
                    else setShowFileViewer(true);
                  }}
                >
                  Apri
                </Button>
              )}

              <div
                style={{
                  width: 65,
                  display: "flex",
                  justifyContent: "flex-end",
                }}
              >
                {props.currentAlbum == DUMMY_ALBUM_IDS.ALL &&
                  props.currentFile?.album?.albumId && (
                    <FolderOpenOutlined
                      style={{
                        fontSize: 22,
                        color: "#ff7f50",
                        alignSelf: "flex-end",
                      }}
                      onClick={() => {
                        props.onClose(false);
                        props.onLinkedAlbum();
                      }}
                    />
                  )}
              </div>
            </div>

            <div
              style={{
                display: "flex",
                gap: 10,
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "fit-content",
                  width: "100%",
                  gap: 10,
                }}
              >
                {props.currentFile ? (
                  <SafeImage
                    preview
                    src={
                      props.currentFile?.fileType == "image"
                        ? props.currentFile.uri
                        : props.currentFile.thumbnail_uri
                    }
                    file={props.currentFile}
                    style={{
                      objectFit: "cover",
                      height: photo_height,
                      width: "100%",
                    }}
                    fallback={
                      <div
                        style={{ width: "100%", height: photo_height }}
                        className="centered"
                      >
                        {/* TODO call service to get thumbnail of any file */}
                        {chooseIconByFileExt(props.currentFile.extension, 60)}
                      </div>
                    }
                  />
                ) : uploadingPreview ? (
                  <SafeImage
                    src={uploadingPreview}
                    preview
                    style={{
                      width: "100%",
                      height: photo_height,
                      objectFit: "cover",
                    }}
                    fallback={
                      <div
                        style={{ width: "100%", height: photo_height }}
                        className="centered"
                      >
                        {/* TODO call service to get thumbnail of any file */}
                        {chooseIconByFileExt(
                          splitNameExtension(uploadingFile?.name)[1],
                          60
                        )}
                      </div>
                    }
                  />
                ) : (
                  <div>
                    <UploadManager
                      type="drag"
                      onFiles={onFiles}
                      onSingleFile={onSingleFile}
                      onFolder={onFolder}
                      onPreviewReady={onPreviewReady}
                    >
                      <div
                        className="centered"
                        style={{
                          height: photo_height,
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                        }}
                      >
                        Trascina qui i tuoi files
                      </div>
                    </UploadManager>

                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: 10,
                      }}
                    >
                      {isInputDirSupported() && (
                        <>
                          <UploadManager
                            type="click"
                            fileType="folder"
                            onFiles={onFiles}
                            onSingleFile={onSingleFile}
                            onFolder={onFolder}
                            onPreviewReady={onPreviewReady}
                          >
                            <Button icon={<FolderAddOutlined />}>
                              Carica una cartella
                            </Button>
                          </UploadManager>

                          <hr style={{ height: 22, margin: 4 }} />
                        </>
                      )}

                      <UploadManager
                        type="click"
                        fileType="file"
                        onFiles={onFiles}
                        onSingleFile={onSingleFile}
                        onFolder={onFolder}
                        onPreviewReady={onPreviewReady}
                      >
                        <Button icon={<FileAddOutlined />}>Carica files</Button>
                      </UploadManager>
                    </div>
                  </div>
                )}
              </div>

              {(uploadingFile || props.currentFile) && (
                <div>
                  {uploadingFile ? (
                    <div>{`Dimensione: ${formatBytes(
                      uploadingFile.size
                    )}`}</div>
                  ) : null}

                  <div>
                    <Form layout="horizontal" form={form}>
                      <Form.Item>
                        <Form.Item
                          className="form-item"
                          name="name"
                          initialValue={props.currentFile?.name}
                          rules={[
                            {
                              required: true,
                              message: "Inserisci il nome del file",
                            },
                          ]}
                        >
                          <Input
                            disabled={readOnly}
                            onChange={() => setEdited(true)}
                            placeholder="Nome"
                            defaultValue={
                              splitNameExtension(uploadingFile?.name)[0]
                            }
                          />
                        </Form.Item>

                        <Form.Item
                          className="form-item small"
                          name="ext"
                          initialValue={props.currentFile?.extension}
                        >
                          <Input
                            disabled
                            defaultValue={
                              splitNameExtension(uploadingFile?.name)[1]
                            }
                          />
                        </Form.Item>
                      </Form.Item>

                      <Form.Item>
                        <Form.Item
                          className="form-item"
                          name="tags"
                          initialValue={props.currentFile?.tagRelationship?.map(
                            (rel) => rel.tag.name
                          )}
                        >
                          <Select
                            mode="tags"
                            placeholder="Tags"
                            allowClear
                            disabled={readOnly}
                            onSelect={(v) => {
                              setTagSearchValue("");
                            }}
                            onChange={() => setEdited(true)}
                            onSearch={(v) => {
                              setTagSearchValue(v);
                              let currentValue = form.getFieldValue("tags") as
                                | string
                                | string[]
                                | undefined;

                              let separators = [","];

                              separators.forEach((sep) => {
                                if (v.endsWith(sep)) {
                                  if (currentValue) {
                                    if (typeof currentValue === "string") {
                                      form.setFieldsValue({
                                        tags: [
                                          currentValue,
                                          v.substr(0, v.indexOf(sep)),
                                        ],
                                      });
                                    } else {
                                      form.setFieldsValue({
                                        tags: [
                                          ...currentValue,
                                          v.substr(0, v.indexOf(sep)),
                                        ],
                                      });
                                    }
                                  } else {
                                    form.setFieldsValue({
                                      tags: [v.substr(0, v.indexOf(sep))],
                                    });
                                  }
                                  setTagSearchValue("");
                                }
                              });
                            }}
                            searchValue={tagSearchValue}
                          >
                            {tags?.map((tag) => (
                              <Option key={tag.tagId} value={tag.name}>
                                {tag.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>

                        <Select
                          style={{ width: "40%", padding: 5 }}
                          placeholder="Album"
                          allowClear
                          value={albumValue}
                          disabled={readOnly}
                          onChange={(v) => {
                            setEdited(true);
                            setalbumValue(v);
                          }}
                        >
                          {userAlbums!
                            //?.filter(
                            //   (a) =>
                            //      a.name.toLowerCase() !== DUMMY_ALBUM_IDS.TEMP
                            //  )
                            .map((album) => (
                              <Option key={album.albumId} value={album.albumId}>
                                {getLabelOf(album)}
                              </Option>
                            ))}
                        </Select>
                        <Tooltip title="Nuovo Album">
                          <div
                            style={{
                              cursor: "pointer",
                              position: "absolute",
                              right: -30,
                              top: 5,
                            }}
                            onClick={() => {
                              showNewAlbumModal();
                            }}
                          >
                            <FolderAddOutlined style={{ fontSize: 25 }} />
                          </div>
                        </Tooltip>
                      </Form.Item>

                      <Form.Item
                        name="description"
                        className="form-item"
                        style={{ width: "100%" }}
                        initialValue={props.currentFile?.description}
                      >
                        <TextArea
                          placeholder="Descrizione"
                          rows={3}
                          disabled={readOnly}
                          onChange={() => setEdited(true)}
                        />
                      </Form.Item>

                      <Form.Item style={{ display: "flex" }}>
                        <Form.Item
                          className="form-item"
                          style={{ width: "50%" }}
                          name="country"
                          hasFeedback
                          initialValue={
                            props.currentFile
                              ? props.currentFile?.place
                                ? props.currentFile?.place.includes("/")
                                  ? props.currentFile?.place.substring(
                                      0,
                                      props.currentFile?.place.indexOf("/")
                                    )
                                  : props.currentFile?.place
                                : undefined
                              : uri.includes(DUMMY_ALBUM_IDS.PLACE + "_")
                              ? initialValueCountry
                              : initialCountry
                          }
                        >
                          <Select
                            placeholder={t("Paese")}
                            showSearch
                            allowClear
                            disabled={readOnly}
                            onClear={() => {
                              setSelectedCountry("");
                              form.setFields([
                                { value: undefined, name: "city" },
                              ]);
                              setEdited(true);
                            }}
                            onChange={(value) => {
                              if (value) {
                                setSelectedCountry(value?.toString());
                                setEdited(true);
                              }
                            }}
                            filterOption={(input, option) =>
                              (option!.children?.toString() ?? "")
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            filterSort={(optionA, optionB) =>
                              (optionA!.children?.toString() ?? "")
                                .toLowerCase()
                                .localeCompare(
                                  (
                                    optionB!.children?.toString() ?? ""
                                  ).toLowerCase()
                                )
                            }
                          >
                            {Object.keys(getLocalizedCountries()).map((key) => {
                              let val = getLocalizedCountry(key);
                              return (
                                <Option key={key} value={key}>
                                  {val}
                                </Option>
                              );
                            })}
                          </Select>
                        </Form.Item>

                        <Form.Item
                          className="form-item"
                          style={{ width: "50%" }}
                          name="city"
                          hasFeedback
                          initialValue={
                            props.currentFile
                              ? props.currentFile?.place
                                ? props.currentFile?.place.includes("/")
                                  ? props.currentFile?.place.substring(
                                      props.currentFile?.place.indexOf("/") + 1
                                    )
                                  : undefined
                                : undefined
                              : initialValueCity
                          }
                        >
                          <Select
                            placeholder={t("Città")}
                            showSearch
                            allowClear
                            disabled={!form.getFieldValue("country")}
                            onChange={() => setEdited(true)}
                            filterOption={(input, option) =>
                              (option!.children?.toString() ?? "")
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                            onSearch={(val) => {
                              clearTimeout(delayTimer);
                              delayTimer = setTimeout(function () {
                                setSearchCity(val);
                              }, 1000);
                            }}
                            filterSort={(a, b) => {
                              if (searchCity) {
                                if (
                                  (
                                    a!.children?.toString() ?? ""
                                  ).toLowerCase() === searchCity.toLowerCase()
                                )
                                  return -1;
                                else if (
                                  (
                                    b!.children?.toString() ?? ""
                                  ).toLowerCase() === searchCity.toLowerCase()
                                )
                                  return 1;
                                else if (
                                  (a!.children?.toString() ?? "")
                                    .toLowerCase()
                                    .startsWith(searchCity.toLowerCase()) &&
                                  !(b!.children?.toString() ?? "").startsWith(
                                    searchCity.toLowerCase()
                                  )
                                )
                                  return -1;
                                else if (
                                  (b!.children?.toString() ?? "")
                                    .toLowerCase()
                                    .startsWith(searchCity.toLowerCase())
                                )
                                  return 1;
                              }
                              return 0;
                            }}
                          >
                            {cities.map((city, index) => (
                              <Option key={index} value={city.name}>
                                {city.name}
                              </Option>
                            ))}
                          </Select>
                        </Form.Item>
                      </Form.Item>

                      {!readOnly && (
                        <Form.Item
                          name="isProtected"
                          className="form-item"
                          style={{ width: "100%" }}
                          initialValue={newProtected}

                          //initialValue={props.currentFile?.isProtected}
                        >
                          <Switch
                            className="safe-switch"
                            checkedChildren="In Cassaforte"
                            unCheckedChildren="Non in Cassaforte"
                            onChange={(v) => {
                              setNewProtected(v);
                              setEdited(true);
                            }}
                          />
                        </Form.Item>
                      )}
                    </Form>
                  </div>

                  {uploadingFile && uploadingFile.size >= MAX_UPLOAD_SIZE ? (
                    <div style={{ color: "red" }}>
                      La dimensione del file non deve superare 100 MB
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>

          <AlbumModal
            open={isCreatingAlbum}
            onClose={() => setisCreatingAlbum(false)}
            onSubmit={createNewAlbum}
          />

          <CreatePostFromFilesModal
            userId={userId!}
            files={sharedFiles}
            close={() => setSharedFiles(undefined)}
            createMultiplePost={createMultiplePost}
            onUnshareFile={unshareFile}
          />

          {props.currentFile && (
            <FileViewer
              file={props.currentFile}
              visible={showFileViewer}
              zIndex={1003}
              onCancel={() => setShowFileViewer(false)}
            />
          )}
        </Modal>
      </LoadingOverlayWrapper>

      {modalListVisible && (
        <MultipleFileForm
          directoryName={uploadingFolder}
          onRemoveFile={(uid) => {
            setFilesList(filesList.filter((f) => f.file.uid !== uid));
          }}
          onCancel={() => {
            setNumFiles(0);
            reset();
            props.onClose(false);

            setFilesList([]);
            setModalListVisible(false);
          }}
          modalListVisible={modalListVisible}
          onClose={(upd) => {
            reset();
            props.onClose(upd);
            setFilesList([]);
          }}
          currentAlbum={props.currentAlbum}
          currentfileList={filesList}
          onChangeModal={handleModal}
        ></MultipleFileForm>
      )}
    </>
  );
};

export default forwardRef(FileForm);
