import React, { useEffect, useState } from "react";
import { Button, Form, Input, message, Modal, Select } from "antd";
import { GenericGroupInterface } from "../../types/GroupTypes";
import { useAppSelector } from "../../store/hooks";
import { selectConnections } from "../../store/auth/authSlice";
import { parseMultiGroup, parseOneToOneGroup } from "../../util/GroupUtil";
import { AlbumInterface } from "../../types/AlbumTypes";
import PeopleList, { ListPersonDto } from "../feed/PeopleList";
import update from "immutability-helper";
import { toast } from "react-toastify";

const { Option } = Select;
interface ShareAlbumModalProps {
  visible?: boolean;
  userId: string;
  album?: AlbumInterface;
  onClose: () => void;
  onSubmit: (shareIds: string[], unshareIds: string[]) => void;
}

const ShareAlbumModal: React.FunctionComponent<ShareAlbumModalProps> = ({
  visible,
  userId,
  album,
  onClose,
  onSubmit,
}) => {
  const [form] = Form.useForm();
  const [members, setMembers] = useState<ListPersonDto[]>([]);
  const [toCanAdd, setToCanAdd] = useState<string[]>([]);
  const connections = useAppSelector(selectConnections);
  const [originalMembers, setOriginalMembers] = useState<ListPersonDto[]>([]);
  const [filter, setFilter] = useState<string>("");

  useEffect(() => {
    if (!album) return;

    let _groups: GenericGroupInterface[] = [];
    connections?.friends.forEach((g) => {
      _groups.push(parseOneToOneGroup(g, userId));
    });

    let memId: string[] = [];
    _groups.forEach((g) => {
      g.members.forEach((p) => {
        if (!memId.some((id) => p.personId === id)) memId.push(p.personId);
      });
    });

    let memIdReduced: string[] = [];
    album.albumSharedToPerson?.forEach((e) => {
      if (memId.some((p) => p === e.uploader.personId))
        memIdReduced.push(e.uploader.personId);
    });

    let _groupsReduced: GenericGroupInterface[] = [];
    _groups.forEach((g) => {
      if (!g.members.some((p) => memIdReduced.includes(p.personId)))
        _groupsReduced.push(g);
    });

    let _toCanAdd: string[] = [];
    _groupsReduced.forEach((g) => {
      if (!_toCanAdd.some((id) => g.groupId === id)) _toCanAdd.push(g.groupId);
    });

    let mem: ListPersonDto[] = [];
    _groups.forEach((g) => {
      let a: ListPersonDto = {
        personId: g.groupId,
        name: g.name,
        selected: _toCanAdd.includes(g.groupId) ? undefined : true,
        surname: "",
        gender: "",
        leaf: false,
        horizontal: false,
      };
      mem.push(a);
    });

    mem.sort((a, b) => {
      if (a.selected && !b.selected) return -1;
      if (!a.selected && b.selected) return 1;
      return 0;
    });
    //mem.forEach((m)=> console.log(m.name + " " + m.selected));

    setMembers(mem);
    setToCanAdd(_toCanAdd);
    setOriginalMembers([...mem]);
  }, [album, visible]);

  const selectMember = (m: ListPersonDto, i: number) => {
    let indexM = members.findIndex((mem) => mem.personId == m.personId);
    let index = originalMembers.findIndex((mem) => mem.personId == m.personId);
    if (index != -1 && indexM != -1) {
      setMembers((list) =>
        update(list, { [indexM]: { selected: { $set: true } } })
      );

      setOriginalMembers((list) =>
        update(list, { [index]: { selected: { $set: true } } })
      );
    }
  };

  const unselectMember = (m: ListPersonDto, i: number) => {
    let indexM = members.findIndex((mem) => mem.personId == m.personId);
    let index = originalMembers.findIndex((mem) => mem.personId == m.personId);
    if (index != -1 && indexM != -1) {
      setMembers((list) =>
        update(list, { [indexM]: { selected: { $set: false } } })
      );

      setOriginalMembers((list) =>
        update(list, { [index]: { selected: { $set: false } } })
      );
    }
  };

  useEffect(() => {
    let filteredList = [...originalMembers];
    filteredList = filteredList.filter((m) => {
      return m.name.toLowerCase().includes(filter.toLowerCase());
    });

    setMembers(filteredList);
  }, [filter]);

  const submit = () => {
    let share: string[] = [];
    let unshare: string[] = [];
    members.forEach((m) => {
      if (toCanAdd.includes(m.personId) && m.selected == false) {
        m.selected = undefined;
      } else if (!toCanAdd.includes(m.personId) && m.selected == true) {
        m.selected = undefined;
      }

      if (m.selected == true) share.push(m.personId);
      else if (m.selected == false) unshare.push(m.personId);
    });

    if (share.length == 0 && unshare.length == 0)
      return toast.warning("Seleziona alemno un destinatario", {
        position: "bottom-right",
        hideProgressBar: true,
      });

    onSubmit && onSubmit(share, unshare);
    close();
  };

  const close = () => {
    form.resetFields();
    onClose();
  };

  return (
    <Modal
      visible={visible}
      footer={[
        <Button onClick={close}>Annulla</Button>,
        <Button type="primary" onClick={() => form.submit()}>
          Modifica
        </Button>,
      ]}
      centered
      destroyOnClose
      maskStyle={{ backdropFilter: "blur(3px)", backgroundColor: "unset" }}
      bodyStyle={{ padding: 40 }}
      onCancel={close}
    >
      <Form layout="vertical" form={form} onFinish={submit}>
        <Form.Item name="searchbox" label="Cerca membro">
          <Input allowClear onChange={(e) => setFilter(e.target.value)} />
        </Form.Item>
        <Form.Item name="members" label="Membri">
          <PeopleList
            people={members}
            selectPerson={selectMember}
            unselectPerson={unselectMember}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShareAlbumModal;
