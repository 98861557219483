import axios from "axios";
import React, { useEffect } from "react";
import {
  authLogout,
  refreshTokenAsync,
  selectToken,
} from "../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { message } from "antd";
import { resetTree } from "../store/tree/treeSlice";
import axiosRetry from "axios-retry";
import { refreshTokenAPI } from "../service/authAPI";
import { toast } from "react-toastify";

const AxiosManager: React.FunctionComponent<{}> = () => {
  const dispatch = useAppDispatch();

  const token = useAppSelector(selectToken);

  // Change authorization token on token change
  useEffect(() => {
    axios.defaults.headers["Authorization"] = !!token
      ? "Bearer " + token
      : null;
  }, [token]);

  //Set axios interceptors
  useEffect(() => {
    if ((axios.interceptors.response as any).handlers.length <= 2) {
      setAxiosInterceptor();
    }
  }, []);

  const onError = (error: string) => {
    toast.error(error, {
      position: "bottom-right",
      hideProgressBar: true,
    });
  };

  const logout = () => {
    console.log("logging out");
    onError("Sessione scaduta");
    dispatch(authLogout());
  };

  const setAxiosInterceptor = () => {
    console.log("SETTING AXIOS INTERCEPTOR");

    axios.interceptors.response.use(
      //2XX codes
      (res) => {
        return res;
      },
      //other codes
      (error) => {
        if (error.response !== undefined) {
          const status = error.response ? error.response.status : null;

          console.log(error.response);
          let msg = error.response.data.message;
          if (
            status !== 401 &&
            msg !== "No notifications found" &&
            msg !==
              "Unexpected error, please contact your system administrator" &&
            msg !== "HTTP 401 Unauthorized"
          ) {
            if (msg) onError(msg);
            else onError(error.message);
          }
        } else {
          // CORS error (no auth) (or something else??)
          console.log(error);
          dispatch(authLogout());
        }
        return Promise.reject(error);
      }
    );
  };

  axiosRetry(axios, {
    retries: 2,

    onRetry: async (count, error, cfg) => {
      let r = await dispatch(refreshTokenAsync());
      let newToken = (r.payload as { token?: string } | undefined)?.token;
      if (cfg?.headers?.["Authorization"] && newToken) {
        cfg!.headers!["Authorization"] = `Bearer ${newToken}`;
      }
    },
    retryCondition: async (error) => {
      if (error.response?.status === 401) {
        return true;
      }
      return false;
    },
    retryDelay: () => 3000,
    shouldResetTimeout: true,
  });

  return null;
};

export default AxiosManager;
