import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "..";
import { OutputNode } from "../../components/tree/TreeTypes";

export interface TreeState {
  flowElements: OutputNode[] | [];
}

export const initialState: TreeState = {
  flowElements: [],
};

export const treeSlice = createSlice({
  name: "tree",
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {
    updateElements: (state, { payload }) => {
      state.flowElements = payload;
    },

    resetTree: (state) => {
      state = initialState;
    },
  },
});

export const selectElements = (state: RootState) => state.tree.flowElements;

export const { updateElements, resetTree } = treeSlice.actions;

export default treeSlice.reducer;
