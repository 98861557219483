import { CopyOutlined } from "@ant-design/icons";
import { Button, message, Tooltip } from "antd";
import { FunctionComponent, useState } from "react";
import { toast } from "react-toastify";
import { JsxElement } from "typescript";
import { COLORS } from "../Constants";

interface CopyButtonProps {
  text: string;
}

const CopyButton: FunctionComponent<CopyButtonProps> = (props) => {
  return (
    <Tooltip title="Copia" placement="bottom">
      <div
        style={{
          cursor: "pointer",
          border: `1px dashed ${COLORS.lightGreen}`,
          padding: 10,
        }}
        onClick={() => {
          navigator.clipboard.writeText(props.text);
          toast.info("Copiato!", {
            position: "bottom-right",
            hideProgressBar: true,
          });
        }}
      >
        {props.text}
      </div>
    </Tooltip>
  );
};

export default CopyButton;
