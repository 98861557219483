import { BellOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { Badge, Layout, Menu, Popover, Progress, Tooltip } from "antd";
import Cookies from "js-cookie";
import React, { useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import {
  COLORS,
  ROUTE_DESK,
  ROUTE_FEED,
  ROUTE_FILES,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PERSON_VIEW,
  ROUTE_PROFILE,
  ROUTE_TREE,
} from "../Constants";
import {
  authLogout,
  selectProfile,
  updateUserPic,
  selectUserPicPath,
  selectUserStorage,
  UserStorage,
  selectConnections,
} from "../store/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import { resetTree } from "../store/tree/treeSlice";
import { COOKIE_BASE } from "../util/CookieManager";
import { bytesToGB } from "../util/PaymentUtil";
import { FallBackType, SafeImage } from "./SafeImage";
import { UserMenu } from "./UserMenu";

const { Sider } = Layout;

const OTHERS_STORAGE_COLOR = "lightgray";

export interface SideMenuProps {
  showHelp?: boolean;
  hideFooter: boolean;
  onHelp?: () => void;
}

export const SideMenu: React.FunctionComponent<SideMenuProps> = ({
  showHelp,
  hideFooter,
  onHelp,
}) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const logout = () => {
    dispatch(authLogout());
  };

  const [usedStoragePerc, setusedStoragePerc] = useState(0);
  const [myUsedStoragePerc, setMyusedStoragePerc] = useState(0);

  const { t } = useTranslation();

  const profile = useAppSelector(selectProfile);
  //const userName = profile?.name+ " "+profile?.surname

  const userImage = useAppSelector(selectUserPicPath);

  let showingCookies = Cookies.get(COOKIE_BASE) !== "true";

  const userStorage = useAppSelector(selectUserStorage);

  useEffect(() => {
    if (userStorage && userStorage.storage > 0) {
      setusedStoragePerc(
        Math.round(
          ((userStorage.usedStorage + userStorage.usedByOthers) /
            userStorage.storage) *
            100
        )
      );
      setMyusedStoragePerc(
        Math.round((userStorage.usedStorage / userStorage.storage) * 100)
      );
    }
  }, [userStorage?.usedStorage]);

  useEffect(() => {
    if (profile?.userPic?.file) {
      dispatch(updateUserPic(profile.userPic.file));
    }
  }, [profile]);

  const getLegendSquare = (color: string) => (
    <div style={{ width: 20, height: 20, backgroundColor: color }} />
  );

  const connections = useAppSelector(selectConnections);

  let newPosts = 0;

  if (connections) {
    connections.friends.forEach((f) => {
      newPosts += parseInt(f.pendingPost);
    });
    connections.groups.forEach((g) => {
      newPosts += parseInt(g.pendingPost);
    });
  }

  return (
    <Sider
      breakpoint="md"
      collapsedWidth="0"
      theme="light"
      style={{ boxShadow: "4px 0px 8px lightgrey", height: "100%" }}
      onCollapse={(collapsed, type) => {}}
    >
      <div className="side-menu">
        <div>
          <SafeImage
            className="image"
            src={userImage}
            fallback={FallBackType.INITIALS}
            fullname={
              profile
                ? { name: profile?.name, surname: profile?.surname }
                : undefined
            }
            alt="user"
          />

          {/*
 <div className="progress-container">
          <div className="text-container">
            <div className="text">{t("Profilo completato al")}</div>
            <div className="perc">{profileCompletion + "%"}</div>
          </div>
          <Progress
            percent={profileCompletion}
            showInfo={false}
            strokeColor={COLORS.neonGreen}
          />
        </div>
*/}

          <Menu
            mode="inline"
            onClick={(info) => {
              switch (info.key) {
                case "albero": {
                  history.push(ROUTE_PERSON_VIEW.replace(":id", ""));
                  break;
                }
                case "feed": {
                  history.push(ROUTE_FEED);
                  break;
                }
                case "logout": {
                  logout();
                  break;
                }
                case "scatola": {
                  history.push(ROUTE_FILES);
                  break;
                }
                case "profile": {
                  history.push(ROUTE_PROFILE);
                  break;
                }
                case "home": {
                  history.push(ROUTE_HOME);
                  break;
                }
                case "scrivania": {
                  history.push(ROUTE_DESK);
                  break;
                }
              }
            }}
          >
            {isMobile ? <UserMenu /> : null}

            <Menu.Item key="home">
              <div className="button">
                <div className="text">Home</div>
                <div
                  className="circle"
                  style={{ backgroundColor: COLORS.lightGreen }}
                />
              </div>
            </Menu.Item>

            <Menu.Item key="feed">
              <div className="button">
                <div style={{ display: "flex", alignItems: "center", gap: 10 }}>
                  <div className="text">Messaggi</div>

                  {newPosts !== 0 && (
                    <Badge count={newPosts} overflowCount={10} />
                  )}
                </div>

                <div
                  className="circle"
                  style={{ backgroundColor: COLORS.blue }}
                />
              </div>
            </Menu.Item>

            <Menu.Item key="albero">
              <div className="button">
                <div className="text">La Mia Famiglia</div>
                <div
                  className="circle"
                  style={{ backgroundColor: COLORS.red }}
                />
              </div>
            </Menu.Item>

            <Menu.Item key="scatola" style={{ margin: "0px !important" }}>
              <div className="button">
                <div className="text">Il Mio Archivio</div>
                <div
                  className="circle"
                  style={{ backgroundColor: COLORS.purple }}
                />
              </div>
            </Menu.Item>

            {/*
            <Menu.Item key="scrivania">
            <div className="button">
              <div className="text">La mia scrivania</div>
              <div
                className="circle"
                style={{ backgroundColor: COLORS.lightGreen }}
              />
            </div>
          </Menu.Item>
          */}

            {/*
<Menu.Item key="profile">
            <div className="button">
              <div className="text">Profilo</div>
              <div
                className="circle"
                style={{ backgroundColor: COLORS.cyan }}
              />
            </div>
          </Menu.Item>
*/}
          </Menu>

          <div
            className="progress-container bottom"
            style={{ cursor: "pointer" }}
            onClick={() => {
              history.push(ROUTE_PAYMENTS);
            }}
          >
            <Popover
              content={() => {
                if (!userStorage) return null;
                let hasOthers = (userStorage.usedByOthers ?? 0) > 0;
                let total = bytesToGB(userStorage.storage);
                let free = bytesToGB(
                  userStorage.storage -
                    (userStorage.usedStorage + userStorage.usedByOthers)
                );

                return (
                  (userStorage.storage ?? 0) > 0 && (
                    <div>
                      <div style={{ display: "flex", gap: 10 }}>
                        {getLegendSquare(getColorForStorage(myUsedStoragePerc))}
                        {`${bytesToGB(userStorage.usedStorage)} GB utilizzati` +
                          (hasOthers ? " da te" : "")}
                      </div>

                      {hasOthers && (
                        <div style={{ display: "flex", gap: 10 }}>
                          {getLegendSquare(OTHERS_STORAGE_COLOR)}
                          {`${bytesToGB(
                            userStorage.usedByOthers
                          )} GB utilizzati da altri utenti`}
                        </div>
                      )}

                      <br />
                      <div>{`Spazio totale: ${total} GB`}</div>
                      <div>{`Spazio disponibile: ${free} GB`}</div>
                    </div>
                  )
                );
              }}
            >
              <div className="text-container">
                <div className="text">{t("Il tuo spazio")}</div>
                <div className="text">{usedStoragePerc + "%"}</div>
              </div>
              <Progress
                percent={usedStoragePerc}
                success={{
                  percent: myUsedStoragePerc,
                  strokeColor: getColorForStorage(myUsedStoragePerc),
                }}
                showInfo={false}
                strokeColor={OTHERS_STORAGE_COLOR}
              />
            </Popover>
          </div>
        </div>
        {showHelp && (
          <div
            id="help-button"
            className="help-button"
            style={{ marginBottom: showingCookies || !hideFooter ? 80 : 20 }}
            onClick={onHelp}
          >
            Ti serve aiuto?
          </div>
        )}
      </div>
    </Sider>
  );
};

function getColorForStorage(value: number) {
  if (value < 50) return COLORS.neonGreen;
  if (value < 75) return COLORS.peach;
  return COLORS.orange;
}
