import { EllipsisOutlined } from "@ant-design/icons";
import { url } from "inspector";
import { FunctionComponent } from "react";
import {
  WhatsappShareButton,
  WhatsappIcon,
  TelegramShareButton,
  TelegramIcon,
  FacebookMessengerShareButton,
  FacebookMessengerIcon,
} from "react-share";
import CopyText from "../components/CopyText";

interface SharePageProps {}

const SharePage: FunctionComponent<SharePageProps> = () => {
  let url = `www.familyworld.it/registration`;
  let title = "Invito a Family World";
  let body =
    "Ciao! Ti condivido un link a Family World! Registrati per condividere ricordi e chattare con me ed il resto della famiglia:";

  const handleSharing = async () => {
    let data: ShareData = { url, title: title, text: body };
    if (navigator.share) {
      try {
        await navigator.share(data).then(() => console.log("Shared"));
      } catch (error) {
        console.log(`Oops! I couldn't share to the world because: ${error}`);
      }
    } else {
      // fallback code
      console.log(
        "Web share is currently not supported on this browser. Please provide a callback"
      );
    }
  };

  return (
    <div className={`landing-page`}>
      <div
        className="background"
        style={{
          height: "100vh",
          backgroundImage: "url(/assets/images/home_background.webp)",
          backgroundSize: "cover",
          backgroundBlendMode: "overlay",
          backgroundColor: "#1f342f",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <div
          style={{
            maxWidth: 400,
            background: "white",
            borderRadius: 30,
            padding: "50px 50px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            gap: 20,
          }}
        >
          <img
            style={{ width: 200 }}
            src="/assets/images/logo_fw_black.png"
            alt="logo"
          />
          <h4>
            Condividi questo link con i tuoi parenti per farli entrare in Family
            World:
          </h4>

          <CopyText text={url} />

          <div style={{ display: "flex", gap: 10 }}>
            {/*
  <EmailShareButton
    url={url}
    openShareDialogOnClick
    subject={title}
    body={body}
  >
    <EmailIcon size={40} />
  </EmailShareButton>
  */}

            <WhatsappShareButton
              windowHeight={600}
              windowWidth={800}
              url={url}
              title={body}
            >
              <WhatsappIcon round size={40} />
            </WhatsappShareButton>
            <TelegramShareButton url={url} title={body}>
              <TelegramIcon round size={40} />
            </TelegramShareButton>
            <FacebookMessengerShareButton
              url={url}
              title={body}
              appId="592425359014722"
            >
              <FacebookMessengerIcon round size={40} />
            </FacebookMessengerShareButton>

            {!!navigator.share && (
              <EllipsisOutlined
                style={{
                  fontSize: 20,
                  height: 40,
                  width: 40,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "lightgray",
                  borderRadius: 40,
                }}
                onClick={() => {
                  handleSharing();
                }}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SharePage;
