import { FirebaseApp, initializeApp } from "firebase/app";
import {
  getAnalytics,
  logEvent,
  setUserProperties,
  setCurrentScreen,
  Analytics,
} from "firebase/analytics";
import {
  getMessaging,
  getToken,
  MessagePayload,
  Messaging,
  onMessage,
} from "firebase/messaging";
import { onBackgroundMessage } from "firebase/messaging/sw";
import { Database, getDatabase } from "firebase/database";

import { useHistory, useLocation } from "react-router-dom";
import { useEffect } from "react";
import { sendFCMToken } from "./service/notificationAPI";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import { retrieveNotifications } from "./store/auth/authSlice";
import {
  NotificationInterface,
  NotificationTypes,
} from "./types/NotificationTypes";
import { PRODUCTION_MODE } from "./Constants";

export const firebaseConfig = {
  apiKey: "AIzaSyCT2C8NkGDJFzfIiR7vts-VOkRzeQhv5As",
  authDomain: "familyworld-3b02e.firebaseapp.com",
  projectId: "familyworld-3b02e",
  storageBucket: "familyworld-3b02e.appspot.com",
  messagingSenderId: "800138177069",
  appId: "1:800138177069:web:e8916c392c12a58705a6a4",
  measurementId: "G-NFMF46TYNC",
  databaseURL:
    "https://familyworld-3b02e-default-rtdb.europe-west1.firebasedatabase.app/",
};

// Initialize Firebase
export class FirebaseConfig {
  static app: FirebaseApp | undefined;
  static analytics: Analytics | undefined;
  static messaging: Messaging | undefined;
  static database: Database | undefined;

  static initAnalytics() {
    console.log("init analytics");
    FirebaseConfig.app = initializeApp(firebaseConfig);
    FirebaseConfig.analytics = getAnalytics(FirebaseConfig.app);
  }

  static initDatabase() {
    console.log("init database");
    FirebaseConfig.app = initializeApp(firebaseConfig);
    FirebaseConfig.database = getDatabase(FirebaseConfig.app);
  }

  static async initMessaging(userId: string) {
    if (!PRODUCTION_MODE) {
      return;
    }
    console.log("init messaging");
    try {
      FirebaseConfig.app = initializeApp(firebaseConfig);
      FirebaseConfig.messaging = getMessaging(FirebaseConfig.app);
      let token = await FirebaseConfig.getFCMToken();
      if (token) {
        sendFCMToken(userId, token);
      }
      return token;
    } catch (e) {
      return null;
    }
  }

  static getFCMToken = async () => {
    if (FirebaseConfig.messaging)
      return await getToken(FirebaseConfig.messaging, {
        vapidKey:
          "BGpBq6A_pF9MSpM7H9s2h-6lRBY2LgWnE34v3Aacuy7DAnRU7r-caQvQPRbc254echQgy5mtFYBZn8-0XdZWhBo",
      })
        .then((currentToken) => {
          if (currentToken) {
            console.log("current token for client: ", currentToken);
            return currentToken;
          } else {
            console.log(
              "No registration token available. Request permission to generate one."
            );
          }
        })
        .catch((err) => {
          console.log("An error occurred while retrieving token. ", err);
          // catch error while creating client token
        });
  };

  static setUserId = (userId: string) => {
    if (FirebaseConfig.analytics)
      setUserProperties(FirebaseConfig.analytics, { userId });
  };

  static setUserProp = (propKey: string, propValue: string) => {
    if (FirebaseConfig.analytics)
      setUserProperties(FirebaseConfig.analytics, { [propKey]: propValue });
  };

  static log = (event: Event) => {
    console.log(event.toString());
    if (FirebaseConfig.analytics)
      logEvent(FirebaseConfig.analytics, event.toString(), {
        // content_type: 'image',
        // content_id: 'P12453',
        //  items: [{ name: 'Kittens' }]
      });
  };

  static logScreen = (screenName: string, screenClass: string) => {
    if (FirebaseConfig.analytics)
      logEvent(FirebaseConfig.analytics, "screen_view", {
        firebase_screen: screenName,
        firebase_screen_class: screenClass,
      });
  };
}

export const HistoryListener: React.FC = () => {
  const history = useHistory();
  useEffect(() => {
    if (FirebaseConfig.analytics && FirebaseConfig.app) {
      const stop = history.listen((location) => {
        console.log("START");

        const page_path = location.pathname + location.search;
        setCurrentScreen(FirebaseConfig.analytics!, page_path);
        logEvent(FirebaseConfig.analytics!, "page_view", { page_path });
      });

      return () => {
        console.log("STOP");
        stop();
      };
    }
  }, []);
  return null;
};

export type Event =
  // basic events
  | "login"
  | "sign_up"
  | "begin_checkout"
  | "purchase"
  | "share"
  | "tutorial_begin"
  | "tutorial_complete"

  // FW custom events (you can add)
  | "user_delete"
  | "tree_person_create";
