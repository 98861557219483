import { FileOutlined, RightOutlined, LeftOutlined } from "@ant-design/icons";
import { Checkbox, Tooltip, Modal } from "antd";
import { initial } from "lodash";
import React, { useState } from "react";
import { chooseIconByFileType } from "../../util/FileUtil";
import { getLocalizedCountry } from "../../util/GeoUtil";
import { SafeImage } from "../SafeImage";
import { FileDto } from "./Types";
import { FileActionButtons } from "./FileActionButtons";

interface FileItemProps {
  file: FileDto;
  selectFile?: () => void;
  downloadFile?: () => void;

  openDeleteModal?: () => void;
  openFileImage?: () => void;
  openShareModal?: () => void;
  readOnly?: boolean;
  multiSelected?: boolean;
  onMultiSelect?: (v: boolean) => void;
  hidecheckbtn?: boolean;
  gridMode: boolean;
}

const FileItem: React.FunctionComponent<FileItemProps> = ({
  file,
  selectFile,
  openFileImage,
  downloadFile,
  openShareModal,
  readOnly,
  openDeleteModal,
  multiSelected,
  onMultiSelect,
  hidecheckbtn = false,
  gridMode = false,
}) => {
  const [showFileViewerModal, setShowFileViewerModal] = useState(false);
  const [showFileViewer, setShowFileViewer] = useState(false);
  const [selectImage, setselectImage] = useState(false);
  const [showTooltipPlace, setShowTooltipPlace] = useState(false);

  const placeText = file.place
    ? file.place?.includes("/")
      ? file.place!.split("/")[1]! +
        (file.place!.split("/").length == 2 ? ", " : " ") +
        getLocalizedCountry(file.place!.split("/")[0])
      : getLocalizedCountry(file.place!)
    : "";

  if (gridMode) {
    return (
      <div
        style={{
          display: "flex",
          width: 220,
          height: 220,
          alignItems: "center",
          gap: 5,
          border: "1px solid lightgray",
        }}
      >
        {!hidecheckbtn && (
          <Checkbox
            disabled={!(onMultiSelect && !readOnly)}
            checked={multiSelected}
            onChange={(e) => onMultiSelect!(e.target.checked)}
          />
        )}
        <SafeImage
          onClick={() => {
            if (openFileImage) openFileImage();
          }}
          src={selectImage ? file.uri : file.thumbnail_uri}
          style={{
            width: "100%",
            height: "100%",
            objectFit: "cover",
            cursor: "pointer",
          }}
          fallback={
            <div className="centered" style={{ width: 80, height: 80 }}>
              {chooseIconByFileType(file.fileType, 35)}
            </div>
          }
        />
      </div>
    );
  }

  const getDescription = () => {
    console.log(file.fileType);

    var docTypes = ["pdf", "text"];
    if (docTypes.includes(file.fileType))
      return file.name + "." + file.extension + "\n" + (file.description ?? "");
    return file.description;
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        padding: 20,
        borderBottom: "2px solid white",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: 20,
          justifyContent: "space-between",
          flex: "auto",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 20 }}>
          {!hidecheckbtn && (
            <Checkbox
              disabled={!(onMultiSelect && !readOnly)}
              checked={multiSelected}
              onChange={(e) => onMultiSelect!(e.target.checked)}
            />
          )}
          <SafeImage
            onClick={() => {
              if (openFileImage) openFileImage();
            }}
            src={selectImage ? file.uri : file.thumbnail_uri}
            style={{
              width: 80,
              height: 80,
              objectFit: "cover",
              cursor: "pointer",
            }}
            fallback={
              <div className="centered" style={{ width: 80, height: 80 }}>
                {chooseIconByFileType(file.fileType, 35)}
              </div>
            }
          />

          <div
            style={{
              width: 150,
              textOverflow: "ellipsis",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            {getDescription()}
          </div>
          <div
            style={{
              marginLeft: 80,
              width: 140,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
          >
            {file.tagRelationship?.map((rel) => (
              <div className="tag-chip" key={rel.id}>
                {rel.tag.name}
              </div>
            ))}
          </div>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              gap: 50,
              padding: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <div className="hidelongtext" style={{ width: 200 }}>
                {file.createdAt}
              </div>
              <Tooltip title={placeText} visible={showTooltipPlace}>
                <div
                  className="hidelongtext"
                  style={{ width: 200 }}
                  onMouseEnter={(e) => {
                    if (
                      e.currentTarget.scrollWidth > e.currentTarget.clientWidth
                    )
                      setShowTooltipPlace(true);
                  }}
                  onMouseLeave={() => {
                    setShowTooltipPlace(false);
                  }}
                >
                  {placeText}
                </div>
              </Tooltip>
            </div>
          </div>
        </div>

        <div style={{ justifySelf: "flex-end" }}>
          <FileActionButtons
            file={file}
            selectFile={selectFile}
            downloadFile={downloadFile}
            openShareModal={openShareModal}
            readOnly={readOnly}
            openDeleteModal={openDeleteModal}
          ></FileActionButtons>
        </div>
      </div>
    </div>
  );
};

export default FileItem;

function setCount(arg0: number) {
  throw new Error("Function not implemented.");
}
