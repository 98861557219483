export function showCaptchaBanner() {
  console.log("show captcha");

  if (!document.body.classList.contains("captcha-visible"))
    document.body.classList.add("captcha-visible");
}

export function hideCaptchaBanner() {
  console.log("hide captcha");

  if (document.body.classList.contains("captcha-visible"))
    document.body.classList.remove("captcha-visible");
}
