import DocViewer, {
  DocRenderer,
  DocViewerRenderers,
} from "@cyntler/react-doc-viewer";
import { Modal, Tooltip } from "antd";
import { CSSProperties, FunctionComponent, useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { FileDto } from "./Types";

interface FileViewerProps {
  file: FileDto;
  visible: boolean;
  viewerStyle?: CSSProperties;
  zIndex?: number;
  onCancel: () => void;
}

const FileViewer: FunctionComponent<FileViewerProps> = ({
  file,
  visible,
  viewerStyle,
  zIndex,
  onCancel,
}) => {
  const [showDocViewerModal, setShowDocViewerModal] = useState(false);
  const [showPlayerModal, setShowPlayerModal] = useState(false);
  const [showPlayerTooltip, setShowPlayerTooltip] = useState(false);

  useEffect(() => {
    if (file?.fileType == "audio" || file?.fileType == "video")
      setShowPlayerModal(true);
    else if (file?.fileType != "image") setShowDocViewerModal(true);
  }, [visible]);

  const MyNoRenderer = () => {
    return (
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyItems: "center",
          width: "fit-content",
          height: "fit-content",
        }}
      >
        Impossibile aprire i file con la seguente estensione: {file?.extension}
      </div>
    );
  };

  return (
    <div className="file-viewer" style={{ display: visible ? "" : "none" }}>
      <Modal
        bodyStyle={{
          height: "fit-content",
          overflow: "hidden",
          display: "flex",
          placeItems: "center",
          alignContent: "center",
          justifyContent: "center",
        }}
        width={"fit-content"}
        zIndex={zIndex}
        visible={visible && showDocViewerModal}
        centered
        closable
        destroyOnClose
        onCancel={() => {
          onCancel();
          setShowDocViewerModal(false);
        }}
        footer={null}
      >
        <DocViewer
          documents={[{ uri: file.uri, fileName: file.name }]}
          pluginRenderers={DocViewerRenderers}
          config={{ noRenderer: { overrideComponent: MyNoRenderer } }}
          style={viewerStyle ?? { height: 800, width: 800 }}
        />
      </Modal>

      <Modal
        bodyStyle={{
          height: "fit-content",
          display: "flex",
          placeItems: "center",
          alignItems: "flex-start",
          alignContent: "center",
          justifyContent: "center",
          flexDirection: "column",
          rowGap: 20,
        }}
        width={"fit-content"}
        zIndex={zIndex}
        visible={visible && showPlayerModal}
        centered
        closable
        destroyOnClose
        onCancel={() => {
          onCancel();
          setShowPlayerModal(false);
        }}
        footer={null}
      >
        <Tooltip title={file.name} visible={showPlayerTooltip}>
          <div
            style={{
              fontWeight: "bold",
              fontSize: 16,
              marginLeft: 10,
              maxWidth: 630,
              overflow: "hidden",
              whiteSpace: "nowrap",
              textOverflow: "ellipsis",
            }}
            onMouseEnter={(e) => {
              if (
                e.currentTarget.clientWidth >=
                Number(
                  e.currentTarget.style.maxWidth.substring(
                    0,
                    e.currentTarget.style.maxWidth.length - 2
                  )
                )
              )
                setShowPlayerTooltip(true);
            }}
            onMouseLeave={() => setShowPlayerTooltip(false)}
          >
            {file.name}
          </div>
        </Tooltip>
        <ReactPlayer
          url={file.uri}
          controls
          height={file.fileType == "audio" ? 60 : "100%"}
        />
      </Modal>
    </div>
  );
};

export default FileViewer;
