import moment from "moment";

export const DATE_FORMAT = "DD/MM/YYYY";
export const FILE_DATE_FORMAT = "YYYY-MM-DD";

export const formatDate = (day: number, month: number, year: number) => {
  if (!day || !month || !year) return;
  let dayStr = day.toString();
  if (dayStr.length === 1) dayStr = "0" + dayStr;

  let monthStr = month.toString();
  if (monthStr.length === 1) monthStr = "0" + monthStr;

  return [dayStr, monthStr, year.toString()].join("/");
};

export const getDate = (date?: string | null) => {
  if (date) {
    return {
      day: date.substring(0, date.indexOf("/")),
      month: date.substring(date.indexOf("/") + 1, date.lastIndexOf("/")),
      year: date.substring(date.lastIndexOf("/") + 1),
    };
  }
};

export const formatMillisToDate = (milliseconds: number) => {
  return moment(milliseconds).format(DATE_FORMAT);
};
