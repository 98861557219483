import axios, { AxiosError } from "axios";
import { PersonDto } from "../components/tree/TreeTypes";
import { API_URL } from "../Constants";
import { UserStorage } from "../store/auth/authSlice";
import { InviteDto, PersonFormValues } from "../types/PersonTypes";
import { formatDate } from "../util/DateUtil";
import { dropboxRedirectUri } from "../pages/Files";

export const getUserProfile = async (username: string) => {
  let resp = await axios
    .get(API_URL + "/graph/person/username/" + username)
    .catch((error: AxiosError) => {
      return error.response;
    });

  if (resp?.status !== 200) {
    console.error(resp);
    return;
    // throw new Error(resp?.statusText)
  }

  console.log(resp);
  return resp?.data as PersonDto;
};

export const getPersonFromInvite = async (inviteId: string) => {
  let resp = await axios
    .get(API_URL + "/graph/public/invitation", {
      params: { inviteId },
      headers: { Authorization: null },
    })
    .catch((error: AxiosError) => {
      return error.response;
    });

  if (resp?.status !== 200) {
    console.error(resp);
    return;
    // throw new Error(resp?.statusText)
  }

  console.log(resp);
  return resp?.data as PersonDto;
};

export const invitePerson = async (personId: string, email?: string) => {
  let resp = await axios
    .put(API_URL + "/graph/user/invite", null, { params: { personId } })
    .catch((error: AxiosError) => {
      return error.response;
    });

  if (resp?.status !== 200) {
    console.error(resp);
    return;
    // throw new Error(resp?.statusText)
  }

  console.log(resp);
  return resp?.data as InviteDto;
};

export const saveDropboxCode = async (code: string, personId: string) => {
  let resp = await axios
    .put(API_URL + "/graph/person/dropbox/code", null, {
      params: {
        code,
        personId,
        redirectUri: dropboxRedirectUri,
      },
    })
    .catch((err: AxiosError) => {
      console.error(err);
      return err.response;
    });
  return resp?.status === 200;
};

export const sendHelpForm = async (values: any, captchaToken: string) => {
  let resp = await axios
    .post(API_URL + "/graph/public/help/", values, {
      params: {
        captchaToken,
      },
    })
    .catch((err: AxiosError) => {
      console.error(err);
      return err.response;
    });
  return resp?.status === 200;
};

export const sendFriendRequest = async (source: string, target: string) => {
  let resp = await axios
    .post(API_URL + "/graph/friendship/friendRequest", null, {
      params: {
        source,
        target,
      },
    })
    .catch((err: AxiosError) => {
      console.error(err);
      return err.response;
    });

  console.log(resp);
  return resp?.status === 200;
};

export const findMatchingProfiles = async (
  values: PersonFormValues,
  onlyUsers = false,
  skip = 0,
  limit = 20
) => {
  let resp = await axios
    .post(
      API_URL + "/graph/persons/find",
      {
        name: values.name,
        surname: values.surname,
        birthDay: formatDate(values.day, values.month, values.year),
        cityOfBirth: values.city,
        countryOfBirth: values.country,
      },
      {
        params: {
          onlyUsers,
        },
      }
    )
    .catch((err) => {
      console.error(err);
      return err;
    });

  console.log(resp);
  if (resp && resp.data && (resp.data as PersonDto[]))
    return resp.data as PersonDto[];
};

export const getUserProfileById = async (id: string) => {
  let resp = await axios
    .get(API_URL + "/graph/person/" + id)
    .catch((error: AxiosError) => {
      return error.response;
    });

  if (resp?.status !== 200) {
    console.error(resp);
    //throw new Error(resp?.statusText)
  }

  console.log(resp);
  return resp?.data as PersonDto;
};

export const getUsersProfiles = async (ids: string[]): Promise<PersonDto[]> => {
  return await Promise.all(ids.map((id) => getUserProfileById(id)));
};

export const getUsedStorage = async (
  userId: string
): Promise<UserStorage | undefined> => {
  let resp = await axios
    .get(API_URL + "/graph/usedStorage", { params: { userId } })
    .catch((error: AxiosError) => {
      return error.response;
    });

  if (resp?.status !== 200) {
    console.error(resp);
    //throw new Error(resp?.statusText)
  }

  return resp?.data as UserStorage;
};
