import React from "react";
import {
  NotificationInterface,
  NotificationTypes,
  SharingNotificationInterface,
} from "../types/NotificationTypes";
import { Button, Menu, Spin } from "antd";
import MenuItem from "antd/lib/menu/MenuItem";
import Text from "antd/lib/typography/Text";
import { useAppDispatch, useAppSelector } from "../store/hooks";
import {
  readNotification,
  respondRequest,
  selectNotifications,
} from "../store/auth/authSlice";
import { CloseOutlined } from "@ant-design/icons";
import { useHistory } from "react-router-dom";
import { ROUTE_FEED, ROUTE_FEED_DETAIL, ROUTE_FOLDER } from "../Constants";
import { DUMMY_ALBUM_IDS } from "../pages/Folder";

interface NotificationListProps {}

const NotificationList: React.FunctionComponent<
  NotificationListProps
> = ({}) => {
  const { data = [], pendingNotifications = 0 } =
    useAppSelector(selectNotifications);

  return (
    <Menu style={{ minWidth: 360 }}>
      <Menu.ItemGroup
        title={data.length > 0 ? "Le tue notifiche" : "Non hai nuove notifiche"}
      >
        {data.map((notification, i) => (
          <NotificationSwitch
            notification={notification}
            index={i}
            key={notification.notificationId}
          />
        ))}
      </Menu.ItemGroup>
    </Menu>
  );
};

export default NotificationList;

const NotificationSwitch: React.FunctionComponent<{
  notification: NotificationInterface;
  index: number;
}> = ({ notification, index }) => {
  switch (notification.notificationType) {
    case NotificationTypes.FRIEND_REQUEST:
      if (notification.notificationStatus === "RECEIVED")
        return (
          <YesNoRequestNotification notification={notification} index={index} />
        );
      else
        return (
          <NotificationWithReadButton
            notification={notification}
            index={index}
          />
        );
    case NotificationTypes.GRAPH_REQUEST:
      if (notification.notificationStatus === "RECEIVED")
        return (
          <YesNoRequestNotification notification={notification} index={index} />
        );
      else
        return (
          <NotificationWithReadButton
            notification={notification}
            index={index}
          />
        );

    case NotificationTypes.SHARED_FILE:
      return (
        <NotificationWithReadButton
          notification={notification}
          index={index}
          link={
            ROUTE_FOLDER.replace(":id", DUMMY_ALBUM_IDS.SHARED) +
            "?fileId=" +
            notification.fileId +
            (notification.sender
              ? "&targetId=" +
                notification.sender?.personId +
                "&targetName=" +
                notification.sender?.name
              : null)
          }
        />
      );

    case NotificationTypes.SHARED_ALBUM:
      return (
        <NotificationWithReadButton
          notification={notification}
          index={index}
          //link={ROUTE_FOLDER.replace(":id", DUMMY_ALBUM_IDS.SHARED)}
        />
      );
    case NotificationTypes.NEW_POST:
      return (
        <NotificationWithReadButton
          notification={notification}
          index={index}
          link={
            notification.groupId
              ? ROUTE_FEED_DETAIL.replace(":context", notification.groupId)
              : undefined
          }
        />
      );

    default:
      console.error("Notification type not supported");
      console.log(notification);
      return null;
  }
};

const YesNoRequestNotification: React.FunctionComponent<{
  notification: NotificationInterface;
  index: number;
}> = ({ notification, index }) => {
  const dispatch = useAppDispatch();

  return (
    <MenuItem
      className="notification-item-container"
      key={notification.notificationId}
    >
      <div className="notification-item">
        <Text className="notification-item-text">{notification.message}</Text>
        {!notification.loading ? (
          <div className="notification-item-action-container">
            <Button
              type="ghost"
              style={{ borderTopRightRadius: 0, borderBottomRightRadius: 0 }}
              onClick={() =>
                dispatch(respondRequest({ index, accepted: true }))
              }
            >
              Accetta
            </Button>
            <Button
              danger={true}
              style={{ borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
              onClick={() =>
                dispatch(respondRequest({ index, accepted: false }))
              }
            >
              Rifiuta
            </Button>
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </MenuItem>
  );
};

const NotificationWithReadButton: React.FunctionComponent<{
  notification: NotificationInterface;
  index: number;
  link?: string;
}> = ({ notification, index, link }) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  return (
    <MenuItem
      className="notification-item-container"
      key={notification.notificationId}
    >
      <div className="notification-item">
        <Text className="notification-item-text">{notification.message}</Text>
        {!notification.loading ? (
          <div className="notification-item-action-container">
            {link && (
              <Button
                type="primary"
                shape="round"
                onClick={() => {
                  dispatch(readNotification(index)).then(() => {
                    history.push(link);
                  });
                }}
              >
                {"Apri"}
              </Button>
            )}

            <Button
              type="primary"
              shape="round"
              icon={<CloseOutlined />}
              onClick={() => dispatch(readNotification(index))}
            />
          </div>
        ) : (
          <Spin />
        )}
      </div>
    </MenuItem>
  );
};
